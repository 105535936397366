import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { environment } from '../environments/environment';
import { Token } from './auth/models/token.model';
import { User } from './auth/models/user.model';
import { LoginService } from './auth/services/login.service';
import { DefaultLanguageService } from './services/default-language.service';

@Injectable()
export class ApiConfInterceptor implements HttpInterceptor {

  private token: Token;
  private user: User;

  constructor(
    private loginService: LoginService,
    private translateService: TranslateService,
    private defaultLanguageService: DefaultLanguageService
  ) {
    this.loginService.getCurrentToken().subscribe(token => {
      this.token = token;
    });

    this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    });
  }

  /**
   * Replaces placeholders in the given URL with corresponding values from the environment configuration.
   *
   * @param {string} url - The URL containing placeholders to be replaced.
   * @returns {string} - The URL with placeholders replaced by environment values.
   */
  private replacer(url: string): string {
    const replacements = new Map<string, string>([
      ['/:apiBase/', environment.api.base],
      ['/:apiServices/', environment.api.services],
      ['/:apiIntegrationsMonitor/', environment.api.integrationsMonitor],
      // ['/:apiDataDogLogger/', environment.api.dataDogLogger],
      ['/:apiFintechStats/', environment.api.fintechStats],
      ['/:apiSatCiec/', environment.api.satCiec],
      ['/:apiChat/', environment.api.chat],
      ['/:apiSignatures/', environment.api.signatures],
      ['/:apiNotes/', environment.api.notes],
      ['/:apiApplicationProxy/', environment.api.applicationProxy],
      ['/:apiOauth/', environment.api.oauth],
      ['/:apiImporter/', environment.api.importer],
      ['/:apiDrive/', environment.api.drive],
      ['/:apiBatches/', environment.api.batches],
      ['/:apiApplicationsExport/', environment.api.applicationsExport],
      ['/:apiInvoices/', environment.api.invoices],
      ['/:apiTenant/', environment.api.tenant],
      ['/:apiUsers/', environment.api.users],
      ['/:apiFiles/', environment.api.files]
    ]);

    // Iterate over the replacements map and apply each replacement
    replacements.forEach((value, key) => {
      if (value) { // Only replace if the value is not undefined or null
        url = url.replace(key, value);
      }
    });

    return url;
  }

  private buildHeaders(request: HttpRequest<any>): { [header: string]: string } {
    const headers: { [header: string]: string } = {
      // 'X-Client-Type': 'web'
    };

    // Se añade la autorización si existe el token.
    if (this.token) {
      headers['Authorization'] = `Bearer ${this.token.access_token}`;
    }

    // Si el request no incluye ya el header 'Accept-Language', se asigna en función de:
    // 1. Idioma del usuario,
    // 2. Idioma actual del servicio de traducción,
    // 3. Valor por defecto del servicio de idioma.
    if (!request.headers.has('Accept-Language')) {
      headers['Accept-Language'] =
        this.user?.language ||
        this.translateService.currentLang ||
        this.defaultLanguageService.get().value;
    }

    return headers;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiUrlPattern = /^\/:api[A-Za-z]*\//;
    // Si la URL no coincide con el patrón, se retorna el request sin modificaciones.
    if (!apiUrlPattern.test(request.url)) {
      return next.handle(request);
    }

    // Se clona el request modificando la URL y agregando los headers correspondientes.
    const modifiedRequest = request.clone({
      url: this.replacer(request.url),
      setHeaders: this.buildHeaders(request)
    });

    return next.handle(modifiedRequest);
  }
}

