import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { plainToInstance } from 'class-transformer';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { setTheme } from 'ngx-bootstrap/utils';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { ActiveToast, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { Login } from './auth/models/login.model';
import { LoginService } from './auth/services/login.service';
import messages_en from './i18n/en';
import messages_es from './i18n/es';
import messages_es_mx from './i18n/es-MX';
import messages_pt from './i18n/pt';
import { PusherMessage } from './models/pusher-message.model';
import { ComponentCommService } from './services/component-comm.service';
import { DefaultLanguageService } from './services/default-language.service';
import { HubSpotService } from './services/hub-spot.service';
import { NavigationHistoryService } from './services/navigation-history.service';
import { PusherService } from './services/pusher.service';
import { TenantService } from './services/tenant.service';
import { ThemeService } from './theme/services/theme.service';
import { FlashService } from './ui/services/flash.service';
import { CacheService } from './services/cache.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('mainFrame', { static: true }) private readonly mainFrame: ElementRef;
  // @ViewChild('modal', { static: true }) private readonly modal: TemplateRef<any>;
  @ViewChild('modalUpdate', { static: true }) private readonly modalUpdate: TemplateRef<any>;
  @ViewChild(ToastContainerDirective, { static: true }) private readonly toastContainer: ToastContainerDirective;

  /**  Controls browsing indicator. */
  public browsing: boolean;
  public progress: number;

  private inlineToasts: { [name: string]: ActiveToast<any> } = {};
  private locale: string = 'es';
  private modalRef: BsModalRef[];
  private subscriptions: Subscription[] = [];
  private updateSub: Subscription;

  /** @ignore */
  constructor(
    private router: Router,
    private loginService: LoginService,
    private localeService: BsLocaleService,
    private titleService: Title,
    private componentComm: ComponentCommService,
    private navigationHistoryService: NavigationHistoryService,
    private element: ElementRef,
    private translateService: TranslateService,
    private defaultLanguageService: DefaultLanguageService,
    private flashService: FlashService,
    private toastrService: ToastrService,
    private hubSpotService: HubSpotService,
    private clipboardService: ClipboardService,
    private tenantService: TenantService,
    private themeService: ThemeService,
    private modalService: BsModalService,
    private pusherService: PusherService,
    private cacheService: CacheService,
    @Inject(DOCUMENT) private document: Document
  ) {
    // Setting up the bootstrap version manually
    // Required from ngx-bootsrap 7
    setTheme('bs3');
  }

  /** @ignore */
  ngOnInit(): void {
    this.setupLanguage();

    // Google Analytics
    (<any>window).gtag('js', new Date());
    (<any>window).gtag('config', environment.google.id);

    this.subscriptions.push(this.componentComm.events.subscribe(event => this.handleComponentEvent(event)));

    this.loginService.checkMailVerified();
    this.localeService.use(this.locale);

    // Top header flash messages
    this.toastrService.overlayContainer = this.toastContainer;
    this.subscribeToFlashMessages();

    // Force refresh on updates
    this.subscriptions.push(this.pusherService.listen('public', 'update').subscribe((event: PusherMessage) => {
      // Sample: {"data": {"version": "4.0.1", "force": false}}
      if (event.data?.force) this.openModal(this.modalUpdate);
      else {
        if (!this.updateSub &&
          event.data?.version &&
          event.data.version !== environment.version) {
          this.updateSub = this.router.events.subscribe(event => {
            // Wait until user navigates to prevent form interruption
            if (event instanceof NavigationEnd) {
              this.updateSub.unsubscribe();
              this.openModal(this.modalUpdate);
            };
          });
        }
      }
    }));

    this.subscriptions.push(this.pusherService.listen('public', 'flush').subscribe((event: PusherMessage) => {
      // Sample: {"data": {"contains": "companies"}}
      this.cacheService.clear(event.data?.contains || undefined);
    }));

    // Clipboard
    this.subscriptions.push(this.clipboardService.copyResponse$.subscribe((respone: IClipboardResponse) => {
      if (respone.isSuccess) this.toastrService.success(this.translateService.instant('GLOBAL.COPIED_CLIPBOARD'));
    }));

    // Tenant
    this.tenantService.initTenant();

    // Scripts
    this.loadScripts(environment.scripts);
  }

  /** Load additional scripts specified in environment */
  private loadScripts(scripts: string[]): void {
    if (scripts) {
      scripts.forEach(scriptUrl => {
        const scriptElement = this.document.createElement('script');
        scriptElement.type = "text/javascript";
        scriptElement.src = scriptUrl;
        this.element.nativeElement.parentNode.appendChild(scriptElement);
      });
    }
  }

  /** Handle events received from ComponentCommService */
  private handleComponentEvent(event: any): void {
    switch (event.name) {
      case 'app-scroll':
        // Scroll content to the top
        this.mainFrame.nativeElement.scrollTo?.(0, 0);
        break;
      case 'app-title':
        // FAS-1935
        this.setTitle(event.title, event.params);
        break;
      case 'progress':
        this.progress = event.value;
        break;
    }
  }

  /** Subscribes to flash messages and toast notifications */
  private subscribeToFlashMessages(): void {
    const flashMessages = [
      { name: 'activation_basic', message: 'HEADER.BASIC_ACTIVATION', type: 'toast-warning', dismissible: false },
      { name: 'password_invalid_token', message: 'HEADER.INVALID_PASSWORD_TOKEN', type: 'toast-warning' },
      { name: 'password_reset', message: 'HEADER.PASSWORD_RESET_SENT' },
      { name: 'password_updated', message: 'HEADER.PASSWORD_UPDATED' },
      // { name: 'verification_completed', message: 'HEADER.VERIFICATION_SUCCESSFUL' },
      // { name: 'verification_failed', message: 'HEADER.VERIFICATION_FAILED', type: 'toast-error' },
      { name: 'verification_pending', message: 'HEADER.VERIFICATION_PENDING', type: 'toast-warning', dismissible: false }
    ];

    flashMessages.forEach(flash => {
      this.flashMessageSubscribe(flash.name, flash.message, flash.type, flash.dismissible);
    });
  }

  public refresh(): void {
    window.location.reload();
  }

  /** Subscribes to messages events. */
  private flashMessageSubscribe(name: string, message: string, type: string = 'toast-success', dismissible: boolean = true, onHidden?: Function): void {
    this.subscriptions.push(this.flashService.message(name).subscribe(value => {
      if (value) {
        // Show
        if (!this.inlineToasts[name]) this.inlineToasts[name] = this.showInlineMessage(this.translateService.instant(message), type, dismissible, onHidden);
      } else {
        // Dismiss
        if (this.inlineToasts[name]) {
          this.toastrService.clear(this.inlineToasts[name].toastId);
          this.inlineToasts[name] = undefined;
        }
      }
    }));
  }

  /** Displays inline messages at the top of the window. */
  private showInlineMessage(message: string, type: string, dismissible: boolean, onHidden?: Function): ActiveToast<any> {
    const toast = this.toastrService.show(message, undefined, {
      timeOut: 10000,
      disableTimeOut: type !== 'toast-success',
      positionClass: 'inline',
      closeButton: dismissible,
      tapToDismiss: dismissible
    }, type);

    if (onHidden) toast.onHidden.pipe(take(1)).subscribe(() => {
      onHidden();
    });

    return toast;
  }

  /** @ignore */
  ngAfterViewInit(): void {
    this.subscriptions.push(this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.setBrowsing(true);

        // Google Analytics 4 update
        (<any>window).gtag('config', environment.google.id, {
          'page_location': window.location.origin + event.url,  // It is necessary to send the complete url
          'page_title': this.titleService.getTitle()
        });

      } else if (event instanceof NavigationEnd) {
        this.navigationHistoryService.add(event.url);
        this.setBrowsing(false);

        this.componentComm.emit({ name: 'app-scroll' });
        this.hubSpotService.setPath(this.router.url);
      } else if (event instanceof NavigationCancel) {
        this.setBrowsing(false);
      }
    }));
  }

  /** 
   * Handles setup of language and translations 
   */
  private setupLanguage(): void {
    this.translateService.setTranslation('es', messages_es);
    this.translateService.setTranslation('es-mx', messages_es_mx);
    this.translateService.setTranslation('en', messages_en);
    this.translateService.setTranslation('pt', messages_pt);
    this.translateService.setDefaultLang('es');

    const login: Login = plainToInstance(Login, this.cacheService.get('login'));
    const userLanguage = login?.user?.language;

    if (userLanguage) {
      this.translateService.use(userLanguage);
    } else {
      this.defaultLanguageService.get().subscribe(language => {
        this.translateService.use(language);
      });
    }

    this.subscriptions.push(this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang) this.document.documentElement.lang = event.lang;
      this.setTitle();
    }));
  }

  /**
   * Sets the browsing animation to give user feddback of response.
   * 
   * @param {boolean} enable - Whether to enable the animation.
   */
  private setBrowsing(enable: boolean): void {
    requestAnimationFrame(() => { // Prevents ExpressionChangedAfterItHasBeenCheckedError
      if (this.browsing != enable) this.browsing = enable;
      if (!enable) this.progress = undefined;
    });
  }

  /** HTML title. */
  private setTitle(text?: string | string[], params?: Object): void {
    let title: string;
    // Set the Document Title
    // https://angular.io/guide/set-document-title
    if (Array.isArray(text)) {
      let titleCollection = [];
      text.forEach(str => {
        titleCollection.push(this.translateService.instant(str, params));
        title = titleCollection.join(' : ');
      });
    } else {
      title = (text) ? this.translateService.instant(text, params) : undefined;
    }

    this.titleService.setTitle(this.themeService.theme.title + (title ? ' - ' + title : ''));
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    const newModal = this.modalService.show(template, {
      class: c,
      ignoreBackdropClick: true,
      keyboard: false
    });

    this.modalRef = [...(this.modalRef || []), newModal];
    newModal.onHide.subscribe(() => {
      // Removes last modal reference
      this.modalRef.pop();
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    const mr = this.modalRef[this.modalRef.length - 1];

    if (mr) {
      mr.hide();
      if (onHide) mr.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.modalRef?.forEach(modal => modal.hide());

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
