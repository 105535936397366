<!-- <div class="container">
  <div class="content-box">
    <div class="row">
      <div class="col-xs-12">
        <div class="loading">
          <spinner  *ngIf="processing" message="{{ 'CELLPHONE_VERIFICATION.MODAL.VALIDATING'|translate }}"></spinner>

          <alert type="success"
          *ngIf="message">
            <span class="material-symbols-rounded text-warning animated live">warning</span>
            <span [innerHtml]="message|translate"></span>
          </alert>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div id="general-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="jumbotron">
          <div *ngIf="processing" class="loading loading-sm">
            <spinner message="{{ 'CELLPHONE_VERIFICATION.MODAL.VALIDATING'|translate }}"></spinner>
          </div>
          <div class="flex-center-distributed"
          *ngIf="message">
            <span class="material-symbols-rounded"
            [class.text-success]="!error"
            [class.text-danger]="error">{{ error ? 'unsubscribe' : 'verified' }}</span>
            <div class="grow"
            [innerHtml]="message|translate">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
