const EMAIL_SUPPORT = 'info@agree.ag';

export default {
  GLOBAL: {
    ACCEPT: 'Accept',
    ACTIONS: "Actions",
    ADD_COMPANY: 'Add Company',
    ADD: 'Add',
    ADVANCED_OPTIONS: 'Advanced options...',
    APP_ERROR_401: '<b>Your session expired...</b><br>You need to re-authenticate.',
    APP_ERROR_403: '<b>You do not have permissions for that action...</b><br>Click here to return to <b>your Companies</b>. If it happens again, contact our support team.',
    APP_ERROR_404: '<b>An error occurred...</b><br>Click here to <b>refresh the page</b>. If it happens again, contact our support team.',
    APP_ERROR_APICALL: '<b>An error occurred...</b><br>Wait a few seconds and try again. If the problem persists, contact our support team.',
    APP_ERROR_DEFAULT: '<b>An error occurred...</b><br>Click here to contact our support team.',
    APPROVE: 'Approve',
    APPROVED: 'Approved',
    ARE_YOU_SURE: 'Are you sure?',
    AUCTION: 'Auction',
    BACK: 'Back',
    BARTER: 'Barter',
    BID: 'Bid',
    BROKER: 'Broker',
    CANCEL: 'Cancel',
    CANCELED: 'Cancelled',
    CANNOT_UNDONE: 'This operation cannot be undone.',
    CERTIFICATE_DEPOSIT: 'Certificate of Deposit',
    CERTIFICATES: 'Certificates',
    CLEAR_SELECTION: 'Clear selection',
    CLEAR: 'Clear',
    COLLECTION: 'Collection <span class="currency">[{{length}} element(s)]</span>',
    COMMERCIAL_MANAGER_HELP: 'Assign products and define limits of operations.',
    COMMERCIAL_MANAGER: 'Commercial team managment',
    COMMISSION: 'Commission',
    COMPANY_ADMIN: 'Companies management',
    COMPANY: 'Company',
    CONFIG_ASSISTANT: 'Setup Wizard',
    CONFIRM: 'Confirm',
    CONTRACT: 'Contract',
    CONTRACTS: 'Contracts',
    COPIED_CLIPBOARD: 'Copied to clipboard!',
    COPY_CLIPBOARD: 'Copy to clipboard',
    COUNTERORDER: 'Counter-order',
    COUNTERPART: 'Counterparty',
    COUNTERPARTS: 'Counterparties',
    COUNTRY_PORT: 'Country / Port',
    CREATE: 'Create',
    CROP_YEAR_PLACEHOLDER: 'Select year',
    CROP_YEAR: 'Crop year',
    CUSTOMER: 'Customer',
    DATA_ERROR_AVAILABLE: 'An error occurred while loading the information.<br>Information may not be available yet, please try again in a few minutes.',
    DATA_ERROR_PERMISSION: 'An error occurred while loading the information.<br>You may not have permissions to view this data.',
    DATE: 'Date',
    DATE_FORMAT: {
      XS: 'MMM d',
      SM: 'dd/MM/yy',
      RG: 'dd/MM/yyyy',
      MD: 'dd/MMM/yy',
      LG: 'dd/MMM/yyyy'
    },
    DATE_LONG: 'EEEE, MMMM d \'of\' yyyy',
    DATE_PLACEHOLDER: 'dd/mm/yyyy',
    DELETE_ALL: 'Delete all',
    DELIVERY_PERIOD: 'Delivery period',
    DELIVERY_PLACES: 'Delivery places',
    DELIVERY_TYPE_ABBR: 'Delivery',
    DELIVERY_TYPE: 'Delivery type',
    DISAPPROVED: 'Disapproved',
    DISTRIBUTOR: 'Distributor',
    DOCUMENTS_LANGUAGE: 'Documents language',
    DOWNLOAD: 'Download',
    DUE_DATE_ABBR: 'Due',
    DUE_DATE: 'Due date',
    EDIT_ADDRESS: 'Edit legal address of <b>{{company}}</b>',
    EDIT_COMPANY: 'Edit Company',
    EDIT: 'Edit',
    ENTER_COMPANY: 'Enter a Company...',
    ERROR_API: '<b>Oops!</b> Something went wrong. Please try again later.',
    EXPORT_XLS_SUCCESS: 'The export request was successful, but the data collection may take some time, so you will receive an e-mail with the downloadable Excel file soon. Thank you for your patience!',
    EXPORT_XLS: 'Export to XLS',
    EXTERNAL_REFERENCE_ABBR: 'Ext. Ref.',
    EXTERNAL_REFERENCE: 'External Reference',
    FEES: 'Fees',
    FILES: 'Files',
    FIXING_CONDITIONS: 'Fixing conditions',
    FIXING_PERIOD: 'Fixing period',
    FORM: 'Form',
    GENERATE_EXPORT: 'Generate export',
    GENERATE_REPORT: 'Generate report',
    GENERATE: 'Generate',
    HAVE_ACCOUNT_ALREADY: 'Already have an account?',
    IDENTITY_VALIDATION: 'Administration of Identity Validations',
    INTEGRATIONS: 'Integraciones',
    INVOICE_NUMBER: 'Invoice number',
    ISSUED_BY: 'Issued by',
    ISSUED: 'Issued',
    KEY_NAME: 'ID',
    LOADING: 'Loading...',
    MAIN_ACTIVITY: 'Main Activity',
    MARKET: 'Market',
    MESSENGER_CENTER: 'Chat center',
    MIRROR_CONTRACT: 'Mirror',
    MY_COMPANIES: 'My Companies',
    NAME: 'Name',
    NEED_HELP: 'Need help?',
    NEGOTIATED_VOLUME: 'Negotiated volume',
    NO_DATA: 'No records found.',
    NOT_AVAILABLE_ABBR: 'N/A',
    NOTES: 'Companies Data',
    OBSERVATIONS_ABBR: 'Obs.',
    OBSERVATIONS: 'Observations',
    OPERATION_ALERTS: 'Operations alerts',
    OPTIONAL: 'Optional',
    ORDER: 'Order',
    ORDERS_ADMIN: 'Working Orders',
    ORIGIN: 'Origin',
    PAYMENT_CONDITION: 'Payment terms',
    PAYMENT_METHOD: 'Payment method',
    PENDING: 'Pendings',
    PERCENTAGE: 'Percentage',
    PERIOD: 'Period',
    PERMISSION_ADMIN: 'Permission management',
    PRICE: 'Price',
    PRIVACY_POLICY: 'Privacy policy',
    PROCESSING: 'Processing...',
    PRODUCT: 'Product',
    QUALITY: 'Quality',
    QUANTITY_ABBR: 'Qty.',
    QUANTITY: 'Quantity',
    RANGE_PLACEHOLDER: 'dd/mm/yy - dd/mm/yy',
    RECEIVER: 'Receiver',
    REFRESH_VIEW: 'This view has changed, click here to <b>refresh the information</b>.',
    REJECT: 'Reject',
    REPRESENTATIVE: 'Representative',
    RETRY: 'Retry',
    REVIEWING: 'Under review',
    SAVE_CHANGES: 'Save changes',
    SAVE: 'Save',
    SELECT_PRODUCT: 'Select a product...',
    SELECT: 'Select...',
    SELL: 'Offer',
    SELLER_ADMIN: 'Counterparties administration',
    SEND: 'Send',
    SHARED_FILES: 'Shared files',
    SOURCE: 'Source',
    SPECIFICATIONS: 'Specifications',
    STATE: 'State',
    STATUS: 'Status',
    SUPPLIER: 'Input/service supplier',
    TABLE: 'Table <span class="currency">[{{length}} row(s)]</span>',
    TEAM: 'Team',
    TEMPLATES_ADMIN_NOTIFICATION_ERROR_MSG: 'Incomplete or invalid JSON, please check it.',
    TEMPLATES_ADMIN_NOTIFICATION_HEADER: 'Manage notification templates inside the platform',
    TEMPLATES_ADMIN_NOTIFICATION_NEW: 'New notification',
    TEMPLATES_ADMIN: 'Templates administration',
    TEMPLATES: 'Templates',
    TERMS_AND_CONDITIONS: 'Terms and Conditions',
    TOGGLE_HIDE: '<span class="hidden-sm hidden-xs">Hide </span><i class="material-symbols-rounded">expand_less</i>',
    TOGGLE_SHOW: '<span class="hidden-sm hidden-xs">Show </span><i class="material-symbols-rounded">expand_more</i>',
    TOTAL: 'Total',
    TRADE: 'Trade',
    TYPE: 'Type',
    UNDO: 'Undo',
    USER_MANUAL: 'User manual',
    USER_PROFILE: 'My Profile',
    VERSION: 'Version',
    VIEW_MORE: 'View more',
    WALKTHROUGH: 'Welcome guide',
    WELCOME: '<h2>Food Smart Trade</h2><h3>Welcome to the B2B platform to buy or sell food in both global and domestic markets</h3><p>We integrate and connect the entire supply chain in an easy, transparent and secure way</p>',
    WITHDRAW: 'Withdraw',
    YES_ACCEPT: 'Yes, accept',
    YES_APPROVE: 'Yes, approve',
    YES_CONFIRM: 'Yes, confirm',
    YES_DELETE: 'Yes, delete',
    YES_REJECT: 'Yes, reject',
    YES_REMOVE: 'Yes, remove',
    YES_SEND: 'Yes, send',
    YES_WITHDRAW: 'Yes, withdraw'
  },
  EMAIL: {
    EMAIL_SEND_ERROR: "Error sending e-mail!",
    EMAIL_SEND_SUCCESS: "E-mail sent successfully!",
    RESEND: "Resend verification e-mail",
  },
  WALKTHROUGH: {
    GENERAL: {
      INTRO_1: '<h5>Welcome to <b>Agree</b>!</h5><p>Before we start, we would like to show you the main elements of our platform.</p><p class="text-right">Ready?...</p>',
      INTRO_2: '<h5>User menu</h5><p>Contains options relevant to your account.</p><p>From here you can edit your profile or select with which of your Companies you will operate.</p>',
      INTRO_3: '<h5>Company menu</h5><p>Configure your team, manage counterparties, generate operations alerts, configure delivery places and select saved templates to create an Order in seconds.</p>',
      INTRO_4: '<h5>Notifications</h5><p>Each time there is a required action, a message will blink here. You will also receive an e-mail.</p>',
      INTRO_5: '<h5>Main navigation bar</h5><p>It allows you to access the different sections of Agree.</p>',
      INTRO_6: '<h5>Create button</h5><p>Finally, the most important button!</p><p>Click here to <b>create your bids or offers</b>.</p>',
      INTRO_7: '<h5>That is all!</h5><p>From time to time, we will share news about the product, such as new features and improvements.</p><p>If you have any doubt, you can send an e-mail to <a href="mailto:' + EMAIL_SUPPORT + '">' + EMAIL_SUPPORT + '</a></p>'
    },
    MARKET: {
      INTRO_1: '<h5>Market levels</h5><p>Check the current market prices, highest <span class="buy">bids</span>, lowest <span class="sell">offers</span> and the last traded values.</p>',
      INTRO_2: '<h5>Filter Orders</h5><p>By selecting the price you will be able to see only the bids or offers for that position.</p>',
      INTRO_3: '<h5>Filter delivery places</h5><p>You can see the summary for specific locations.</p>',
      INTRO_4: '<h5>Spot and forward</h5><p>The first column shows the available position, the following are the months that correspond to forwarded positions.</p>'
    },
    WORKING_ORDERS: {
      INTRO_1: '<h5>Working Orders</h5><p>Identify the Order that interests you, select it to negotiate or <span class="buy">buy</span> and <span class="sell">sell</span>.</p>'
    },
    MY_OPERATIONS: {
      INTRO_1: '<h5>My operartions</h5><p>This is your control panel where you can manage your Orders: you can see your pending actions and the status of each of your trades.</p>',
      INTRO_2: '<h5>Trade documents</h5><p>Once a negotiation is closed you will be able to <b>download the track record</b> that includes all the actions carried out, including responsible and date/time.</p><p>And if the Order is booked, you can <b>download the Bussines confirmation or Contract</b> and check the terms agreed between the parties.</p>'
    },
    TRADED_ORDERS: {
      INTRO_1: '<h5>Traded Orders</h5><p>See the <b>Orders booked in the public channel</b> in real time, without disclosure of the counterparties.</p><p>Orders booked in the private channel are not listed.</p>'
    },
    COMPANY_TRADED_ORDERS: {
      INTRO_1: '<h5>Compnay trades</h5><p>Here, you\'ll find all the Orders traded by your Company, with access to the <b>Business Confirmation or Contract</b> and the <b>Track record</b>.</p>'
    }
  },
  HEADER: {
    BASIC_ACTIVATION: '<b>Your Company cannot operate.</b> We may need to validate some things. Get in touch with <a href="mailto:' + EMAIL_SUPPORT + '">support team</a>.',
    CREATE_AUCTION: 'Create Auction',
    CREATE_ORDER: 'Create Order',
    INVALID_PASSWORD_TOKEN: '<b>The link is invalid or has expired.</b>',
    LOGOUT: 'Sign off',
    NOTIFICATIONS: 'Notifications',
    PASSWORD_RESET_SENT: '<b>Reset password instructions has been sent</b> to your e-mail account.',
    PENDING_REQUESTS: 'Pending requests',
    SELECT_LANGUAGE: 'Language',
    SUPER_ADMIN_HEADER: 'Super Admin',
    VERIFICATION_PENDING: '<b>Pending verification!</b>\
      A verification e-mail has been sent to your e-mail address.\
      Please, confirm your address in order to continue.',
    VERIFICATION_SUCCESSFUL: '<h1>E-mail verified!<small><br>You can now operate.</small></h1><p>Close this window and return to the application.</p>',
    VERIFICATION_FAILED: '<h1>Your e-mail could not be verified<small><br>Please, contact our <a href="mailto:' + EMAIL_SUPPORT + '">support team</a>.</small></h1>',
    PASSWORD_UPDATED: '<b>Password updated!</b>\
      You can proceed to enter the platform.'
  },
  LOGIN: {
    DONT_HAVE_ACCOUNT: '<span class="hidden-xs">Don\'t have an account?&nbsp;&nbsp;</span><b>Sign up for free</b>',
    EMAIL: 'E-mail address',
    ERROR: 'The e-mail and password you entered <b>are incorrect</b>. <a href="/reset-password">Did you forget your password?</a>',
    FORGOT_PASSWORD: 'Forgot your password?',
    GOOGLE: 'Sign in with Google',
    HEADING: 'Enter your <b>e-mail</b> and <b>password</b>',
    LOGIN: 'Sign in',
    OTHER_OPTIONS: 'Other options',
    PASSWORD: 'Password'
  },
  SIGNUP: {
    CREATE_ACCOUNT: 'Create Account',
    DISCLAIMER: '<b>Important!</b> By registering, you accept the <a href="/links/terms">Terms and Conditions</a> and the <a href="/links/privacy">Privacy policy</a>.',
    EMAIL_EXISTS_ERROR: 'The entered e-mail already exists.',
    EMAIL: 'E-mail address',
    HAVE_ACCOUNT_ALREADY: '<span class="hidden-xs">Already have an account?</span>&nbsp;&nbsp;<b>Log in</b>',
    HEADING: 'Sign up <b>for free</b>',
    HELP_BLOCK: 'Complete your information, <b>register has no cost</b>.<br><small>All fields are required.</small>',
    INVALID_FORM_ERROR: 'Correct your information and try again',
    LAST_NAME: 'Last name',
    LAST_NAMES: 'Last names',
    PASSWORD_CONFIRM: 'Re-enter password',
    PASSWORD_HELP: '<b>It must contain at least</b> 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 number.',
    PASSWORD: 'Password',
    UNKNOWN_ERROR: 'An error occurred, try later.'
  },
  PASSWORD_RESET: {
    HEADING: 'Reset password',
    HELP_BLOCK: 'Enter your e-mail address and in a few minutes you will receive a link to create a new password.',
    EMAIL: 'E-mail address',
    EMAIL_VERIFICATION_ERROR_0: 'Your e-mail has not been verified yet!',
    EMAIL_VERIFICATION_ERROR_1: 'Click',
    EMAIL_VERIFICATION_ERROR_2: 'here',
    EMAIL_VERIFICATION_ERROR_3: 'to send a new verification e-mail.'
  },
  UPDATE_PASSWORD: {
    EXPIRED_TOKEN: 'The token is invalid or has expired.',
    HELP: 'Enter your new password.',
    LABEL_CONFIRM: 'Enter your new password again',
    LABEL_NEW: 'New password',
    PLACEHOLDER_CONFIRM: 'Enter your new password again',
    PLACEHOLDER_NEW: 'New password',
    TITLE: 'Update password',
    UPDATE: 'Update'
  },
  COMPANIES: {
    NO_COMPANY: 'You don\'t belong to any Company yet. You have two ways to join one:',
    NO_COMPANY_1: 'If your Company <b class="text-danger">is not registered</b> yet, create it by clicking <b>Add...</b>',
    NO_COMPANY_2: 'If your Company <b class="text-success">is already registered</b>, ask the Administrator to <b>join the team</b>',
    ADD_COMPANY: 'Register a new Company',
    ADD_COMPANY_HELP: 'If you have questions, contact <a href=\'mailto:' + EMAIL_SUPPORT + '\'>our support team</a>.',
    VERIFY_EMAIL: 'Remember to check your spam folder. If you did not receive any e-mail, you can try to resend it again.',
    ACTIVATION_HELP: 'Thank you very much for registering your Company. In the coming days, we will contact you to inform you of the steps to follow.',
    SELECT_HELP: 'Select the Company to operate with.',
    BASIC: 'Pre-activation',
    WELCOME: 'Welcome, <b class="text-capitalize">{{name}}</b>!'
  },
  SELECT_MARKET: {
    HEADING: 'Select the Market where your Company will operate.',
    HEADING_TYPE: 'Select the Market type where your Company will operate.'
  },
  REGISTER_COMPANY: {
    AUTHORIZATION: 'Power of the signatories',
    CITY: 'City',
    COUNTRY: 'Country',
    ERROR_MESSAGE: 'There was an error, try again later.',
    FILE_DESCRIPTION: 'Upload a readable image or a PDF file.',
    FISCAL_IDENTIFICATION: 'Fiscal Identification',
    IDENTIFICATION: 'Copy of {{documento}} of the authorizer',
    LEGAL_ADDRESS: 'Legal address',
    LOGO_DESCRIPTION: 'Optional, it will be used as the Company image. Upload a square image of 140px or more.',
    LOGO: 'Company logo',
    MY_POSITION: 'My role in the Company',
    NAME_PLACEHOLDER: 'Company name',
    NAME: 'Company name',
    NAMES: 'Names',
    FIRST_SURNAME: 'Paternal surname',
    SECOND_SURNAME: 'Mother\'s surname',
    POSITION_DESCRIPTION: 'The registration of the legal entity must be submitted by a Legal Representative or any other person with legal capacity.',
    POSITION: 'Role',
    POSTAL_CODE: 'ZIP Code',
    STATE: 'State / Province',
    STATUTE: 'Statute of the Company',
    STREET: 'Street and number, P.O. box, C/O',
    TAX_CERTIFICATE_HELP: 'Upload PDF issued by SAT.',
    TAX_CERTIFICATE: 'Tax Certificate',
    TELEPHONE: 'Phone'
  },
  FILE_EXTRACT: {
    AND_MORE: '...and more.',
    DESCRIPTION: 'Extract data from your documents with artificial intelligence.',
    DISCLAIMER: 'It is important to validate the data obtained. The model can identify more than one value for each field.',
    EXTRACT: 'Extract data',
    EXTRACTING: 'Extracting...',
    FIELDS: 'Fields to extract:',
    SUGGESTIONS: 'Suggestions:'
  },
  FILE_INPUT: {
    AVAILABLE: 'Available files',
    DROP_HERE: 'Drag and drop files here...',
    GENERATING: 'Generating files...',
    MULIPLE_SELECTED: 'Files selected',
    OPEN_FILE: 'Open file...',
    PREVIEW: 'Preview...',
    REJECTED: 'The following files format or size is not supported: <b>{{files}}</b>.',
    SELECT_FILE: 'Select file',
    SELECT_FILES: 'Select file(s)',
    SELECTED: 'File selected',
    UPLOAD_FILE: 'Upload a file...',
    UPLOAD_FILES: 'Upload files...',
    UPLOADING: 'Uploading...',
    DELETE_CONFIRM: {
      TITLE: 'Delete',
      MODAL_TITLE: 'Are you sure to delete this file?'
    }
  },
  COMPANY: {
    BARTERS: 'Barters',
    COMPANY_TRADED_ORDERS: 'Company trades',
    EXECUTION: 'Execution',
    FINTECH: 'Financing',
    IMPORTED_DATA: 'Imported data',
    MARKET_PRICES: 'Market prices',
    MARKET_SUMMARY: 'Market levels',
    MY_OPERATIONS: 'My operations',
    NOT_LISTED: 'Can\'t find the Company?',
    PREORDERS: 'Pre-orders',
    SIGNATURES: 'Signatures',
    SLOTS: 'Slots',
    STATISTICS: 'Statistics',
    TRADED_ORDERS: 'Market trades',
    WORKING_ORDERS: 'Working Orders'
  },
  COMPANY_SETUP: {
    CERTIFICATES_HELP: 'Manage the Certificates of your Company.',
    COMPANY_LOCATIONS_HELP: 'Define the delivery places for your Company.',
    HEADING: 'Setup Wizard',
    INTEGRATIONS_HELP: 'Connect via API.',
    NOTES_HELP: 'Manage information from other Companies',
    SELECT_HELP: 'Select any of the options below.',
    SELLER_ADMIN_HELP: 'Select the counterparties which you want to trade with.', // Approve with what Companies you want to do business.'
    TEAM_HELP: 'Create Users for your Company and assign them different roles.',
    WELCOME_1: '<b>Welcome!</b> Before starting it\'s necessary to setup some aspects of your Company.',
    WELCOME_2: 'You will always be able to come back and modify these options. It is important to keep this information updated.'
  },
  ORDER: {
    CREATE_CERTIFICATE: 'Create Certificate',
    DEPOSIT_LOCATION: 'Deposit location',
    NETWORK_ONLY: 'For Network members only',
    NEW_CERTIFICATE: 'New Certificate of Deposit',
    NO_RECORDS: 'Orders not yet registered.', // 'The platform doesn\'t record movements yet.',
    OWN_PRODUCED: 'It\'s own production (primary)',
    PRIVATE: 'Private Order',
    PRODUCTION: 'Production'
  },
  AUCTION_FORM: {
    HEADING: 'New Auction',
    AUTHORIZED_BUYERS: {
      HELP: 'The buyers you select are the only ones who will be able to enroll in this Auction.'
    },
    TYPE: {
      HELP_0: 'According to your activity, you can only publish ',
      HELP_1: ' Auctions.',
      BUY_HELP: '<b>Reverse Auction</b>, sellers compete by offering decreasingly lower prices.'
    },
    SCOPE: {
      MY_NETWORK_HELP: 'The Auctions published to <b>My Network</b> will only be visible to Companies in your <a href="/company/{{companyId}}/network?order_by=-verification_date">Company Network</a>.',
      OPEN_HELP: 'The Auctions in the <b>public channel</b> are visible to all, but only the authorized counterparties will be able to enroll.<br>Once the transaction is booked, it will appear in the section <b>Market Trades</b>, without disclosure of the counterparties.',
      PRIVATE_HELP: 'The Auctions in the <b>private channel</b> are invisible to the market, they are only visible to the counterparties you invite.'
    },
    DATE: {
      DATE: 'Date of the Auction',
      DURATION: 'Duration',
      DURATION_HELP: 'Once started and the first offer is received, <b>the Auction can not be withdrawn until the time runs out</b>. The Auctions must end before the close of market day.',
      DURATION_PLACEHOLDER: 'Select a duration',
      EXTEND: 'Extend the duration by at least one minute since the last offer.',
      EXTEND_HELP: 'This extension is <b>repeated until no last minute offers are registered</b>.',
      MINUTES: 'minutes',
      TIME: 'Star time',
      TIME_HELP: 'Time for domestic market {{market}}. Those interested can register until the specified time.'
    },
    PRICE: {
      MAX: 'Maximum offer',
      MIN: 'Minimum bid'
    },
    STEP: {
      LABEL_BUY: 'Step',
      LABEL_SELL: 'Step',
      BUY_HELP: 'Minimum value of decrease between offers.',
      SELL_HELP: 'Minimum value of increment between bids.'
    }
  },
  ORDER_FORM: {
    EDITING: 'Editing',
    HEADING: 'New Order',
    NO_COMPANY_LOCATIONS: 'Your Company <b>does not have defined delivery places</b>. <a class="alert-link" href="/company/{{companyId}}/locations">Click here</a> to add a place.',
    PREVIEW: 'Preview',
    PUBLISHING: 'Publishing...',
    COMPANY_REPRESENTED_BY: "Companies represented by the broker",
    TYPE: {
      BOOK_HELP_1: 'Any of the authorized counterparties that you define will be able to book directly.',
      BOOK_HELP_2: 'Any Company will be able to book directly.',
      BOOK_HELP: '<span class="{{class}}">{{type}}</span> Orders <b>are firm</b>.',
      HELP_0: 'According to your activity, you can only publish ',
      HELP_1: ' Orders.',
      PREBOOK_HELP: 'In <span class="{{class}}">{{type}}</span> Orders, counterparties can only <b>request a pre-book</b>, which you must accept to book.'
    },
    DELIVERY_TYPE: {
      PLACEHOLDER: 'Select a delivery type'
    },
    BROKER: {
      HELP: 'The broker will receive a <b>Pre-order</b> and he can publish it, merge it with other Pre-orders or reject it.',
      LABEL: 'Is there a broker involved?',
      PLACEHOLDER: 'No'
    },
    SCOPE: {
      LABEL: 'Which channel do you want to use?',
      MY_NETWORK_HELP: 'The Orders published to <b>My Network</b> will only be visible to Companies in your <a href="/company/{{companyId}}/network?order_by=-verification_date">Company Network</a>.',
      MY_NETWORK: 'My Network',
      NO_OPEN_HELP: 'Your Company <b>cannot operate in the Public market</b>. We may need to validate some things. If interested, get in touch with us at <a href="mailto:' + EMAIL_SUPPORT + '">' + EMAIL_SUPPORT + '</a>.',
      OFFLINE_HELP: 'An <b>offline transaction</b> is declarative, reflects a trade that was confirmed off the platform and <b>won\'t require confirmation</b> from the counterparty.',
      OFFLINE: 'Offline',
      OPEN_HELP: 'The Orders in the <b>Public channel</b> are visible to all, but only the authorized counterparties will be able to see your Company and interact with it.<br>Once the Orders are booked, they will appear in the section <b>Market Trades</b>, without disclosure of the counterparties.',
      OPEN: 'Public',
      PRIVATE_HELP: 'The Orders in the <b>Private channel</b> are invisible to the market, they are only visible to the counterparties of your choice.',
      PRIVATE: 'Private'
    },
    PRODUCT: {
      NOT_LISTED: 'Didn\'t find your product?',
      // NOT_SUSTAINABLE: 'Not sustainable',
      SUSTAINABLE: 'Sustainable'
    },
    IMAGES: {
      LABEL: 'Images',
      HELP: 'Select up to 20 images.'
    },
    PRICE: {
      MAX_HELP: '<b>Maximum</b> value that you are willing to pay.',
      MAX: 'Maximum price',
      MIN_HELP: 'Base price. <b>Minimum</b> value that you are willing to accept.',
      MIN: 'Minimum price'
    },
    QUANTITY: {
      FIXED_HELP: 'For <b>Fixed</b> quantity the Order will be closed with the first book.',
      HELP_0: '',
      HELP_1: ' Orders can be for <b>Fixed</b> or <b>Maximum</b> amount.<br>',
      MAX_HELP: 'For <b>Maximum</b> quantity multiple sellers may "offer" different quantities for the same Order.'
    },
    PERIOD: {
      EXPIRATION_HELP_0: 'If the Order is still active after',
      EXPIRATION_HELP_1: 'the platform will automatically move the period, respecting its duration.'
    },
    DELIVERY_LOCATION: {
      LABEL: 'Delivery place(s)',
      LOADING: 'Looking for places...',
      TITLE: 'Add delivery place'
    },
    AUTHORIZED_BUYERS: {
      ERROR_EMPTY_LOCATION: '<b>There are no registered buyers in the selected places!</b> To be able to publish the Order, at least one authorized buyer is required.',
      ERROR_EMPTY_SELECTION: 'Select at least one Company as authorized buyer.',
      HELP: 'The buyers you select are the only ones who can see all the details of your Order, book it or send you a counter Order.',
      LABEL: 'Authorized buyers',
      LOADING: 'Looking for buyers...'
    },
    LOADING_RATE: {
      LABEL: 'Loading rate',
      PLACEHOLDER: 'Loading rate'
    },
    ALTERNATIVE_LOADING_RATE: {
      LABEL: 'Alternative loading rate'
    },
    PORT: {
      LABEL: 'Port',
      PLACEHOLDER: 'Select a port'
    },
    BERTH: {
      LABEL: 'Berth',
      PLACEHOLDER: 'Select a berth'
    },
    ALTERNATIVE_BERTH: {
      LABEL: 'Alternative berth',
      PLACEHOLDER: 'Alternative berth'
    },
    ALTERNATIVE_CONDITION: {
      LABEL: 'Alternative term'
    },
    ARBITRATION_CHAMBER: {
      LABEL: 'Arbitration chamber',
      PLACEHOLDER: 'Select a chamber'
    },
    EXPIRATION_DATE: {
      LABEL: 'Expiration date',
      HELP: 'Date on which the publication will be automatically withdrawn by the system.'
    },
    CONTRACT: {
      HELP: 'The following Contract is generated based on the data provided. You can <b>edit</b>, <b>delete</b> or <b>add</b> new clauses.',
      LANGUAGE_HELP: 'You can also select the <b>language</b> in which the related documentation will be generated.'
    },
    OBSERVATIONS: {
      HELP: 'Use this field for additional information such as slots, commissions, exchange rates or others.'
    },
    CANCEL_CONFIRM: {
      BODY: 'When canceling, the Order will be available to operate again.',
      CONFIRM_BUTTON: 'Yes, discard changes.',
      CONTINUE_BUTTON: 'Continue editing',
      TITLE: 'Do you want to cancel and discard changes?'
    }
  },
  PRIVATE_COMPANIES_SELECTOR: {
    HELP_BUY: 'Find the <b>Buyer</b> you want to trade with. Remember that you can add as many Companies as you want.',
    HELP_SELL: 'Find the <b>Seller</b> you want to trade with. Remember that you can add as many Companies as you want.'
  },
  ORDER_PREVIEW: {
    HELP: '<b>Preview.</b> Define which terms you want to identify as "non-negotiable".',
    WARNING: 'This Order will be automatically withdrawn at the end of the day (23:59 hours GMT {{offset}}).',
    EXPIRATION: 'This Order will be automatically withdrawn on {{day}}, {{month}} {{date}}, {{year}} (GMT {{offset}}).',
    BACK: {
      LABEL_0: 'Back',
      LABEL_1: 'to edit mode',
      TITLE: 'Back to edit mode'
    },
    PUBLISH: {
      LABEL: 'Publish',
    },
    EDIT_CONFIRM: {
      TITLE: 'Are you sure you want to save the changes?',
      BODY: 'If exists, all open Counter-orders of this Order will be automatically canceled.',
      CONFIRM_BUTTON: 'Yes, save'
    }
  },
  NEGOTIABLE_GROUP: {
    NOT_NEGOTIABLE: 'Not negotiable',
    DISCARD: 'Discard changes',
    PREVIOUS_VALUE: 'Previous value: ',
    CURRENT_VALUE: 'Current value: '
  },
  PROPOSAL: {
    ADJUSTED_FIELDS: {
      DELIVERYDATE: '<b>Heads up!</b> The start date was in the past, therefore it has been moved to today.'
    },
    PRICE: {
      PLACEHOLDER: '0.00',
      LABEL_MIN: 'Minimum price',
      LABEL_MAX: 'Maximum price',
      OFFER_MIN: 'Minimum offer',
      OFFER_MAX: 'Maximum offer'
    },
    DELIVERY_LOCATIONS: {
      BUYER_OPTION: 'Buyer\'s option'
    },
    QUALITY: {
      PLACEHOLDER: 'Not specified'
    },
    ARBITRATION_CHAMBER: {
      LABEL: 'Arbitration chamber',
      PLACEHOLDER: 'Select a chamber'
    },
    CONTRACT: {
      DEVIATIONS: 'There are deviations from the standard contract',
      CLAUSE: {
        PLACEHOLDER: 'Write the clause...',
        ADD: 'Add clause',
        ADD_ABBR: 'Add',
        TITLE: 'Title',
        EDIT: 'Edit clause',
        EMPTY: 'The clause cannot be empty!',
        DELETE: {
          LABEL: 'Delete clause',
          TITLE: 'Are you sure you want to delete this clause?'
        }
      },
      TO_BE_COMPLETED: 'To be completed by the platform'
    },
    LOADING_RATE: {
      LABEL: 'Loading rate',
      PLACEHOLDER: 'Loading rate'
    },
    PORT: {
      LABEL: 'Port',
      PLACEHOLDER: 'Select a port'
    },
    BERTH: {
      LABEL: 'Berth',
      PLACEHOLDER: 'Select a berth'
    }
  },
  ORDER_SUMMARY: {
    BROKER_BUYER: 'Buyer\'s Broker',
    BROKER_SELLER: 'Seller\'s Broker',
    BUYER: 'Buyer',
    DISCLOSE: 'Edit counterparties...',
    DISCLOSE_CONFIRM: 'Disclose',
    DISCLOSE_HELP: 'Upon disclosure of the represented Companies, the counterparty will be notified and new versions of the associated documents will be generated to include this information.',
    DISCLOSURE_BUYERS: 'Disclosure of Buyers',
    DISCLOSURE_SELLERS: 'Disclosure of Sellers',
    NO_DISCLOSURE: 'Undisclosed',
    PAUSED: 'Paused',
    SELLER: 'Seller'
  },
  AUCTION_VIEW: {
    PREAUCTION: 'Pre-Auction',
    EDIT_CONFIRM: {
      TITLE: 'Are you sure you want to edit this Auction?',
      BODY_AUCTION: 'While editing, the Auction will remain paused and hidden for the rest of the Companies.',
      BODY_PREAUCTION: 'While editing, the Auction will remain paused and the broker won\'t be able to process it.',
      CONFIRM_BUTTON: 'Yes, edit'
    },
    CANCEL_CONFIRM: {
      TITLE: 'Withdraw Auction',
      TITLE_AUCTION: 'Are you sure you want to withdraw this Auction?',
      TITLE_PREAUCTION: 'Are you sure you want to remove this Pre-auction?',
      BODY_AUCTION: 'All enrolled Companies will be notified.',
      BODY_PREAUCTION: ''
    },
    MODULE: {
      BID: 'Bids',
      BIDS: 'Bid(s)',
      BIDS_HISTORY: 'Bids history',
      CLOSING: 'Closing...',
      COMPANY_ENROLLED: 'Your Company is enrolled to this Auction.',
      COMPANY_ENROLLED_HELP: 'You\'ll be notified when the Auction starts.',
      CURRENT_BID: 'Current bid',
      CURRENT_OFFER: 'Current offer',
      ENDED: 'Auction ended.',
      ENROLLED: 'Enrolled Companies:',
      ENROLL_BUTTON: 'Enroll to the Auction',
      ENROLL_HELP: 'You\'re able to enroll until the Auction starts. From that moment, this Auction will be visible only to the participants.',
      EXTENDED: 'The duration was extended by {{seconds}} seconds due to a last minute offer.',
      LAST_BID: 'Last bid',
      LAST_OFFER: 'Last offer',
      LIVE: 'Auction is live!',
      MAXIMUM_BID: 'You can also enter your maximum bid:',
      MAXIMUM_BID_SET: 'You have set your maximum bid at:',
      MINIMUM_OFFER: 'You can also enter your minimum offer:',
      MINIMUM_OFFER_SET: 'You have set your minimum offer at:',
      NONE_ENROLLED: 'There are no enrolled Companies yet.',
      OFFER: 'Offer',
      OFFERS: 'Offer(s)',
      OFFERS_HISTORY: 'Offers history',
      PLACE_BID: 'Place bid',
      PLACE_BID_HELP_0: 'The platform will automatically increase your bid by',
      PLACE_BID_HELP_1: 'against other bids until the maximum is reached.',
      PLACE_OFFER: 'Place offer',
      PLACE_OFFER_HELP_0: 'The platform will automatically lower your offer by',
      PLACE_OFFER_HELP_1: 'against other offers until the minimum is reached.',
      STARTING: 'Starting...',
      STEP_HELP_0: 'This Auction has a',
      STEP_HELP_1: 'step defined.',
      TIME_LEFT: 'Time left:',
      TIME_TO_START: 'Time to start:',
      WINNING_BID: 'Your bid is winning!',
      WINNING_BID_HELP: 'You\'ll be notified if a better bid is placed.',
      WINNING_OFFER: 'Your offer is winning!',
      WINNING_OFFER_HELP: 'You\'ll be notified if a better offer is placed.',
      WITHDRAWN: 'Auction withdrawn.',
      WON_AUCTION: 'You won this Auction!',
      ENROLLED_MODAL: {
        TIME: 'Date and time of enrollment',
        INVITATION_SENT: 'Invitation sent'
      },
      HISTORY: {
        CURRENT: 'Current',
        WINNER: 'Winner',
        TIME: 'Date and time',
        OFFER: 'Offer',
        BID: 'Bid',
        YOUR_OFFER: 'Your offer',
        YOUR_BID: 'Your bid',
        OFFER_OMITTED: 'Omitted. There was an earlier offer defined automatically.',
        BID_OMITTED: 'Omitted. There was an earlier bid defined automatically.'
      }
    },
    EDIT: 'Edit Auction...',
    REPUBLISH: 'Re-publish Auction...'
  },
  ORDER_VIEW: {
    PREORDER: 'Pre-Order',
    RELATED_PRODUCTS: 'Related products',
    EXPIRATION: 'This Order will be withdrawn on {{day}}, {{month}} {{date}}, {{year}} (GMT {{offset}}).',
    BOOKED_ALERT: {
      TEXT_0: 'Important!',
      TEXT_1: '',
      TEXT_2: 'of the',
      TEXT_3: 'defined for this Order has already been booked.',
      WITHDRAW: 'Do you want to withdraw it?'
    },
    EDIT_CONFIRM: {
      TITLE: 'Are you sure you want to edit this Order?',
      BODY_ORDER: 'While editing, the Order will remain paused and hidden for the rest of the Companies.',
      BODY_PREORDER: 'While editing, the Order will remain paused and the broker won\'t be able to process it.',
      CONFIRM_BUTTON: 'Yes, edit'
    },
    CANCEL_CONFIRM: {
      TITLE: 'Withdraw Order',
      TITLE_ORDER: 'Are you sure you want to withdraw this Order?',
      TITLE_PREORDER: 'Are you sure you want to remove this Pre-order?',
      BODY_ORDER: 'If exists, all active Counter-orders associated with this Order will be closed.',
      BODY_PREORDER: ''
    },
    REJECT_CONFIRM: {
      TITLE: 'Reject Order',
      TITLE_ORDER: 'Are you sure you want to reject this Order?',
      TITLE_PREORDER: 'Are you sure you want to reject this Pre-order?'
    },
    STATUS: {
      REVIEW_PENDING_BROKER_ORDER: '<b>Pending review.</b> The broker has not yet processed this Order.',
      REVIEW_PENDING_BROKER_PREORDER: '<b>Pending review.</b> The broker has not yet processed this Pre-order.',
      REVIEW_PENDING_ORDER: '<b>Pending review.</b> You have not processed this Order yet.',
      REVIEW_PENDING_PREORDER: '<b>Pending review.</b> You have not processed this Pre-order yet.',
      REJECTED_ORDER: '<b>Rejected.</b> The broker decided not to publish this Order.',
      REJECTED_PREORDER: '<b>Rejected.</b> The broker decided not to publish this Pre-order.',
      CANCELED_ORDER: '<b>Canceled.</b> This Order was withdrawn.',
      CANCELED_PREORDER: '<b>Canceled.</b> This Pre-order was withdrawn.',
      PUBLISHED_ORDER: '<b>Published.</b> The broker has decided to publish this Order.',
      PUBLISHED_PREORDER: '<b>Published.</b> The broker has posted this Pre-order.',
      EXPIRED_ORDER: '<b>Canceled.</b> This Order was automatically withdrawn by the platform at the end of the day.',
      EXPIRED_PREORDER: '<b>Canceled.</b> This Pre-order was automatically withdrawn by the platform at the end of the day.',
    },
    PREBOOK_MODAL: {
      TITLE: 'Make an offer ',
      BODY: 'Enter the amount of tons to be offered.',
      CONFIRM_BUTTON: 'Pre-book'
    },
    UNAVAILABLE_MODAL: {
      TITLE: 'Order not available',
      BODY: 'This Order is no longer available to operate.'
    },
    EDIT: 'Edit Order...',
    REPUBLISH: 'Re-publish Order...',
    BOOK: 'Book',
    PREBOOK: 'Pre-book',
    REVIEW: 'Review...',
    NEGOTIATION_REQUEST: 'Negotiation request'
  },
  COUNTERORDER_VIEW: {
    BOOK: 'Book',
    BOOKING_DATE_FORMAT: 'EEEE, MMMM d \'of\' yyyy \'at\' HH:mm',
    NEW: 'New Counter-order',
    LINKED: 'Linked trades',
    AMENDMENT: {
      ALERT: '<b>Heads up!</b> The conditions of the agreement made on <b>{{date}}</b> are being reviewed. The original conditions will remain in effect until the amendments are accepted, at which point new versions of the related documentation will be generated.',
      SEND: 'Propose amendments',
      ACCEPT: {
        TITLE: 'Accept the amendments',
        BODY: 'All suggested amendments are about to be accepted. A new document will be generated reflecting the new conditions. Are you sure you accept all the amendments?',
        BUTTON: 'Accept the amendments'
      },
      REJECT: {
        TITLE: 'Reject all amendments',
        BODY: 'All suggested amendments are about to be ignored, the conditions originally negotiated will be restored. Are you sure to reject all amendments?'
      }
    },
    CANCELLATION: {
      ALERT: '<b>Heads up!</b> This Trade made on <b>{{date}}</b> is being considered to be cancelled. The original conditions will remain in effect until cancellation is confirmed.',
      ACCEPT: {
        TITLE: 'Cancel Trade',
        BODY: 'You are about to accept the proposal to cancel this Business. Are you sure?<br><br>This action cannot be undone.',
        BUTTON: 'Accept and cancel'
      }
    },
    NEGOTIATION_REQUEST_CONFIRM: {
      TITLE: 'Are you sure to accept the negotiation request?',
      BODY: 'The Counter-order will be firm, the counterparty will be able to book directly.'
    },
    NEGOTIATION_REQUEST_REJECT: {
      TITLE: 'Are you sure to reject the negotiation request?'
    },
    STATUS: {
      PAUSED: '<b>Order paused.</b> It is not possible to carry out operations on this Counter-order momentarily.',
      SENT: '<b>Counter-order sent.</b> Waiting for counterparty to reply.',
      RECEIVED: '<b>Counter-order received.</b> You must reply.',
      CANCELED: '<b>Counter-order canceled.</b>',
      REJECTED: '<b>Counter-order rejected.</b>',
      BOOKED: '<b>Booked.</b>',
      AMENDMENT: {
        IN_PROGRESS: '<b>Negotiating amendments.</b> Waiting for the counterparties.',
        SENT: '<b>You suggested amendments to the original agreement</b>. Waiting for counterparty to reply.',
        RECEIVED: 'The counterparty proposes <b>amendments to the original agreement</b>. You must reply.'
      },
      CANCELLATION: {
        IN_PROGRESS: '<b>Negotiating amendments.</b> Waiting for the counterparties.',
        SENT: '<b>You suggested amendments to the original agreement</b>. Waiting for counterparty to reply.',
        RECEIVED: 'The counterparty proposes <b>amendments to the original agreement</b>. You must reply.'
      },
      PREBOOK: {
        RECEIVED: '<b>Pre-book received.</b> You must reply.',
        REQUESTED: '<b>Pre-book sent.</b> Waiting for counterparty to reply.',
        REJECTED: '<b>Pre-book rejected.</b>'
      },
      DISCLOSURE: {
        SENT: '<b>Disclosure request sent.</b> Waiting for counterparty to reply.',
        RECEIVED: '<b>Disclosure request received.</b> You must reply.',
        ANSWERED: '<b>Disclosure request answered.</b> Waiting for counterparty to reply.'
      },
      NEGOTIATION_REQUEST: {
        SENT: '<b>Negotiation request sent.</b> Waiting for counterparty to reply.',
        RECEIVED: '<b>Negotiation request received.</b> You must reply.',
        ACCEPTED: '<b>Negotiation request accepted.</b>',
        REJECTED: '<b>Negotiation request rejected.</b>'
      }
    },
    UNAVAILABLE_MODAL: {
      TITLE: 'Counter-order not available',
      BODY: 'This Counter-order is no longer available to operate.'
    }
  },
  MAX_QUANTITY: {
    DESCRIPTION: 'Booking this negotiation would imply exceeding the defined maximum quantity.',
    MAX_QUANTITY: 'Maximum quantity',
    BOOKED: 'Booked',
    THIS_NEGOTIATION: 'Current negotiation',
    TOTAL: 'Total',
    CONTINUE: 'Yes, continue',
    TO_BE_OFFERED: 'To be Offered'
  },
  MY_OPERATIONS_VIEW: {
    NO_RECORDS: 'The Company doesn\'t record movements yet.',
    SUMMARIZE: 'A total of <span class="quantity">{{quantityOrder}}</span> Order(s)',
    TOOLTIPS: {
      REPUBLISH: 'Republish Order',
      REPUBLISH_AUCTION: 'Republish Auction'
    },
    BULK_REPUBLISH: {
      TITLE: 'Bulk republish',
      MODAL_TITLE: 'Do you want to republish Orders?',
      ACCEPT_BUTTON: 'Republish selected Orders',
      MODAL_DESC_PRE: 'On',
      MODAL_DESC_POST: 'the platform automatically canceled the following Orders. Select those you want to republish.',
      TOOLTIP_DATE: 'This date was automatically moved to today, respecting the duration of the period.',
      EDIT_PRICES: 'Edit prices',
      PUBLISHING: 'Publishing...'
    }
  },
  NEGOTIATION_LIST: {
    UNDER_NEGOTIATION: 'Under negotiation',
    COUNTERORDERS: 'Counter-order(s)',
    BOOKED: 'Booked',
    OF: 'of',
    AMENDMENT_SENT: 'Amendment sent',
    AMENDMENT_RECEIVED: 'Amendment received',
    COUNTERORDER_SENT: 'Counter-order sent',
    COUNTERORDER_RECEIVED: 'Counter-order received',
    PREBOOK_REQUESTED: 'Pre-book requested',
    PREBOOK_RECEIVED: 'Pre-book received'
  },
  TRADED_VIEW: {
    SUMMARIZE: 'Showing <span class="quantity">{{quantity}}</span> trade(s)',
    SUMARIZE_TONS: ' for a total of <span class="quantity">{{quantity}}</span> {{unit}}.',
    SUMARIZE_PRIVATE_TONS: 'Private trades for <span class="quantity">{{quantity}}</span> {{unit}}.',
    PLACEHOLDER: 'The platform doesn\'t record movements yet.'
  },
  NEGOTIATION_ACTIONS: {
    AMENDMENTS: 'Propose amendments',
    ASSIGN_SLOTS: 'Assign Slots',
    CHAT: 'Send a message',
    DELEGATE_TRADE_HELP: '<b>Important!</b> The management will no longer be in charge of your Company.',
    DELEGATE_TRADE: 'Delegate Trade to a Broker',
    DELEGATE: 'Delegate Trade',
    DOWNLOAD_BUSINESS_CONFIRMATION: 'Download business confirmation',
    DOWNLOAD_CONTRACT: 'Download Contract',
    DOWNLOAD_TRACK_RECORD: 'Download track record',
    EDIT_BOOKING_DATE_HELP: '<b>Important!</b> The modification of the trade date will be reflected in the track record and all parties involved will be notified.',
    EDIT_BOOKING_DATE: 'Edit booking date',
    EXTERNAL_ID: 'Manage external ID',
    LINK_NEGOTIATIONS: 'Link Trades',
    REQUEST_CANCELLATION_BODY: 'Your counterpart will receive a cancellation request that they must accept in order to effectively cancel this Trade.',
    REQUEST_CANCELLATION: 'Request cancellation',
    TURN_BARTER: 'Turn into a Barter'
  },
  COMPANY_TRADED_VIEW: {
    BOUGHT: 'Bought',
    LIQUIDATED: 'Liquidated',
    BONUS: 'Bonus',
    NO_RECORDS: 'The platform doesn\'t record movements yet.',
    SOLD: 'Sold',
    SUMMARIZE: 'Showing <span class="quantity">{{quantity}}</span> trade(s)',
    SUMARIZE_VOLUME: ' for a total of <span class="quantity">{{quantity}}</span> {{unit}}.',
    UNLOADED: 'Applied',
    VIEWMODE: {
      GROUPED: 'Grouped by Products',
      UNGROUPED: 'Ungrouped'
    }
  },
  PREORDERS_VIEW: {
    NO_RECORDS: 'The Company doesn\'t record Pre-orders yet.',
    SUMMARIZE: 'Showing <span class="quantity">{{quantity}}</span> Pre-order(s)',
    SELECTED: '<span class="quantity">{{quantity}}</span> Pre-order(s) selected',
    DESCRIPTION_0: 'These are the Orders assigned by your customers. You can review, edit and merge them with other organizations of other customers to focus on the offer in a single Order of greater volume, or reject it. Your clients will be able to know if the assigned Order was reviewed and published or rejected by your Company.',
    DESCRIPTION_1: 'If you assigned a broker to operate your Order, in this screen you can know if the Order was reviewed and published or rejected by the broker.',
    TABLE_HEADER: {
      BROKER: 'Assigned Broker'
    },
    REVIEW: 'Review...',
    CANCEL_CONFIRM: {
      MODAL_TITLE: 'Cancel Pre-orders',
      TITLE_0: 'Are you sure to cancel these Pre-orders?',
      TITLE_1: 'Are you sure to cancel this Pre-order?',
      BODY_0: 'Pre-orders: {{ id }}',
      BODY_1: 'Pre-order: {{ id }}'
    },
    REJECT_CONFIRM: {
      MODAL_TITLE: 'Reject Pre-orders',
      TITLE_0: 'Are you sure to reject these Pre-orders?',
      TITLE_1: 'Are you sure to reject this Pre-order?',
      BODY_0: 'Pre-orders: {{ id }}',
      BODY_1: 'Pre-order: {{ id }}'
    }
  },
  TEAM_VIEW: {
    ACTIVE: 'Active',
    ADD_USER: 'Add User',
    EMAIL: 'E-mail',
    HEADING: 'Create Users for your Company and assign them different roles.',
    IDENTITY_NUMER: 'Identity number',
    INVITATION_SENT: 'Invitation sent',
    NAME_LASTNAME: 'Name and last name',
    NAME: '{{company}} team',
    RESTRICT_VISIBILITY: 'Restrict visibility to your zones',
    ROLE: 'Role',
    USERS: 'Users',
    DELETE_CONFIRM: {
      MODAL_TITLE: 'Delete User',
      TITLE: 'Are you sure you want to remove this User from the team?',
      BODY: 'If you wish, you can always include this same User again later.'
    },
    INVITE_MODAL: {
      ADD_USER_BUTTON: 'Add User',
      ALREADY_EXISTS: 'The User is already part of the Company.',
      BODY: 'Enter a valid e-mail and define a role. We will send an e-mail to the User to guide him in the registration process.',
      EMAIL: 'E-mail',
      ROLE: 'Role',
      SELECT_ROLE: 'Select a role',
      TITLE: 'Add User to {{company}}'
    }
  },
  MANAGERS: {
    MANAGERS: 'Managers',
    MANAGER: 'Manager',
    APPLY_TO_BE_MANAGER: 'Apply to become a representative',
    REPRESENTS: 'Represents',
    NO_MANAGERS: 'Your company does not have loaded representatives',
    REQUEST: {
      REQUEST: 'Aplicação',
      SEND: 'Send request'
    },
    STATUS: {
      ACTIVE: 'Active',
      PENDING: 'Pending',
      ACCEPTED: 'Accepted',
      ACCEPT: 'Accept request'
    },
    DELETE_CONFIRM: {
      MODAL_TITLE: 'Unlink representative',
      TITLE: 'Are you sure to unlink this representative from your company?',
      BODY: 'This user will lose all permissions and access to your company.'
    }
  },
  COUNTERPARTIES: {
    APPROVED: 'Approved',
    HEADING: 'Approve in this section the counterparties with which your Company may operate.',
    MY_NETWORK: 'My Network',
    NEW: 'New!',
    NO_COUNTERPARTS: 'No counterparties registered.',
    VERIFICATION_DATE: 'Verification date',
    STATUS: {
      REMOVE_TITLE: 'Are you sure to block this Company?',
      REMOVE_BODY: 'This Company currently belongs to your Network. By blocking it, you will also be removing this relation.',
      REMOVE_TOOLTIP: 'Block Company',
      YES_REMOVE: 'Yes, block'
    },
    NETWORK: {
      ACCEPT: 'Accept request',
      ADD: 'Add to my Network',
      CANCEL: 'Cancel request',
      PENDING: 'Pending',
      REMOVE_BODY: 'You can add it again later, but it may require an acceptance from the other Company.',
      REMOVE_TITLE: 'Are you sure to remove this Company from your Network?',
      REMOVE_TOOLTIP: 'Remove from my Network',
      WITHDRAW_BODY: 'You can resubmit a new request later.',
      WITHDRAW_TITLE: 'Do you want to withdraw this request?',
      MODAL: {
        ADD_MORE: 'Add {{quantity}} rows...',
        BULK_LOAD: 'Bulk load',
        DESCRIPTION: 'Companies that you add and do not belong to the open Market will only be visible to your Company.',
        EMAIL: 'Administrator e-mail',
        EMAILS_ERROR: '<p><b>One of the emails entered is yours.</b></p>Remember that according to our <a href="/links/terms">Terms and Conditions</a>, only representatives of the Company can register as Administrators. If you are indeed authorized, complete the <a href="/select-market">registration form</a>.',
        EMAILS_HELP: 'Enter the e-mail of the users of the Company you are adding. We will e-mail them to sign up.',
        EMAILS: 'Administrators emails',
        FISCAL_ID_HELP: 'Search the Company by its fiscal ID.',
        MODE_NOT_AVAILABLE: 'This mode is not available on small screens.',
        SINGLE_LOAD: 'Individual load',
        TITLE: 'Invite a Company'
      }
    }
  },
  COMPANY_LOCATIONS_VIEW: {
    TITLE: '{{company}} delivery places',
    ADD_LOCATION_BUTTON: 'Add Place',
    HEADING: 'Manage in this section your Company\'s delivery places.',
    INFO: '<b>Important!</b> Remember to complete all the places where your Company buys. You won\'t be able to see Sellers or buy in places you have not specified.',
    LOCATION_NOT_DEFINED: 'You haven\'t defined any delivery place yet.',
    ZONE: 'Zone',
    LOCATION: 'Location',
    BUYER_OPTION: 'Buyer\'s option',
    DELETE_CONFIRM: {
      MODAL_TITLE: 'Delete Place',
      TITLE: 'Are you sure you want to delete this Place?'
    },
    ADD_LOCATION_TITLE: 'Add a delivery place for {{company}}'
  },
  CERTIFICATES: {
    TITLE: 'Company Certificates',
    ADD_CERTIFICATE: 'Add Certificate',
    HEADING: 'Manage the Certificates of your Company. Current documents will be visible to your counterparties and you can link them to your Orders for traceability purposes.',
    NO_DATA: 'No Certificate has been added yet.',
    TABLE: {
      PRODUCT_CATEGORY: 'Category',
      NUMBER: 'No.',
      DATES: 'Validity',
      SCOPE: 'Scope',
      VERIFIED: 'Verified'
    },
    DELETE_CONFIRM: {
      MODAL_TITLE: 'Delete Certificate',
      TITLE: 'Are you sure you want to delete this Certificate?'
    },
    MODAL: {
      TITLE: 'Add Certificate',
      BODY: 'The Certificates and documents you send will be published on the platform. These documents could be sent to third-party authorities for verification.',
      HOLDER: 'Certificate Holder',
      HOLDER_PLACEHOLDER: 'Holder...',
      HOLDER_HELP: 'It must be consistent with the holder/name of the applicant printed on the Certificate.',
      TYPE_PLACEHOLDER: 'Select...',
      PRODUCT_CATEGORY: 'Product category',
      PRODUCT_CATEGORY_PLACEHOLDER: 'Enter the category...',
      NUMBER: 'No.',
      NUMBER_PLACEHOLDER: 'Enter number/reference...',
      NAME_PLACEHOLDER: 'Select one...',
      OTHER_NAME_PLACEHOLDER: 'Enter the name...',
      ISSUED_BY_PLACEHOLDER: 'Select...',
      OTHER_ISSUED_BY_PLACEHOLDER: 'Enter the name...',
      OPTION_OTHER: 'Other...',
      DATE_FROM: 'Valid from',
      DATE_TO: 'Expiration date',
      SCOPE: 'Scope',
      SCOPE_PLACEHOLDER: 'Certificate scope...',
      SCOPE_HELP: 'Enter the products and relevant audit information covered by the Certificate (and the one that excludes), e.g. the certificate verifies the packing of fresh food for transportation and conservation.',
      IMAGE: 'Certificate image',
      IMAGE_HELP: 'Upload a readable capture of the Certificate. Supported formats: JPG, PNG, GIF or SVG.'
    },
    BUTTON: {
      TITLE: 'Open Company Certificates',
      MODAL: '{{company}}\'s current Certificates'
    }
  },
  LOCATION_PICKER: {
    ADD_LOCATION_BUTTON: 'Add Place',
    TITLE: 'Add Place',
    BODY_0: 'You can either define a <b>Zone</b> or a <b>City</b>.',
    BODY_1: 'Remember that you can add as many places as you want.',
    SELECT_ZONE: 'Select a Zone',
    INCLUDE_LOCATIONS: 'Cities included:',
    PLACEHOLDER: 'Enter a City...',
    CONFIRM_BUTTON: 'Add Place'
  },
  TEMPLATE_NAME_PICKER: {
    SAVE_AS_TEMPLATE_BUTTON: 'Save as Template...',
    SAVE_AS_TEMPLATE_BUTTON_ABBR: 'Template...',
    TITLE: 'Enter Template title',
    CONFIRM_BUTTON: 'Save Template',
    DESCRIPTION: 'Templates allow you to create Orders quickly and can be reused as many times as you want. Give it a name that will help you easily identify it later.'
  },
  REFERENCE_NUMBER_PICKER: {
    TITLE: 'Manage external ID',
    DESCRIPTION: 'You can associate an external identifier with this transaction, for example, one generated by the internal management system of the Company. This will generate a new version of the associated documentation (track record, Contract, business confirmation, etc.) reflecting this data.',
    PLACEHOLDER: 'External ID...'
  },
  TRACK_RECORD: {
    ADD_COMMENT: 'Add comment',
    COMMENT: 'Comment...',
    COMMENT_PLACEHOLDER: 'Enter a comment...',
    FIELD: 'Field',
    NEW_VALUE: 'New value',
    ORIGINAL_VALUE: 'Original value',
    PRIVATE: 'Only visible to my Company',
    PRIVATE_HELP: 'Private comments won\'t be included in the generated documentation.',
    TIE_BREAKER: 'Omitted. There was an earlier bid defined automatically.',
    TITLE: 'Track record',
    USER_AND_ACTION: 'User and action',
    FIELDS: {
      PRICE_UNIT: 'Currency',
      PRICE_YEAR: 'Year Premium',
      PRICE_MONTH: 'Month Premium',
      ARBITRATION_CHAMBER: 'Arbitration chamber',
      DATE_FROM: 'Date from',
      DATE_TO: 'Date to',
      CLAUSES: 'Clause',
      REFERENCE: 'External reference'
    },
    EVENTS: {
      ATTACHMENT: {
        CREATED: 'attached file',
        DELETED: 'deleted file',
        DOWNLOADED: 'downloaded file',
      },
      AUCTION: {
        BIDDED: 'made an offer with this data:',
        CREATED: 'created the Auction.',
        FINISHED: 'Auction finished.'
      },
      BARTER: {
        ACCEPTED: 'accepted the terms of the Barter.'
      },
      CERTIFICATE_OF_DEPOSIT: {
        CREATED: 'created the Certificate of deposit.'
      },
      CHAT: {
        FILE: {
          SENT: ''
        },
        MESSAGE: {
          SENT: ''
        }
      },
      COMMENT: {
        CREATED: 'commented:'
      },
      COMPANY: {
        DISABLED: 'Disabled company.',
      },
      CREDIT: {
        CREATED: 'started the Application process.',
        GRANTED: 'indicated the Application as granted.',
        REJECTED: 'rejected the Application.',
        RESTORED: 'restored the Application to a previous version.',
        UPDATED: 'made changes to the Application.',
        WITHDRAWN: 'withdrew the Application.'
      },
      DOA: {
        APPROVED: 'The delegation of authority was approved.',
        CREATED: 'created a delegation of authority.',
        REJECTED: 'The delegation of authority was rejected.',
        WITHDRAWN: 'The delegation of authority was withdrawn.',
        VOTE: {
          APPROVED: 'cast their vote: Approved.',
          REJECTED: 'cast their vote: Rejected.'
        }
      },
      ENVELOPE: {
        CANCELED: 'canceled the Envelope.',
        COMPLETED: 'All signers signed.',
        CREATED: 'created the Envelope.',
        SIGNERS: {
          DEFINED: 'defined the following signers:',
          NOTIFIED: 'The signers were notified.',
          SIGNED: 'One of the signers signed'
        }
      },
      NEGOTIATION: {
        BOOKED: 'booked.',
        ORIGINAL_BOOKING_DATE_UPDATED: 'modified the original booking date.',
        PRE_BOOKED: 'requested a pre-book.',
        REJECTED: 'rejected.',
        UPDATED: 'sent a Counter-order with these changes:',
        WITHDRAWN: 'canceled the Counter-order.',
        AMENDMENT: {
          ACCEPTED: 'accepted the amendments.',
          REJECTED: 'rejected the amendments.',
          UPDATED: 'proposed the following amendments:'
        },
        BROKER: {
          UPDATED: 'delegated the management of the Trade to another Company.'
        },
        CANCEL: {
          ACCEPTED: 'agreed to cancel the Trade.',
          REJECTED: 'refused to cancel the Trade.',
          REQUESTED: 'requested to cancel the Trade.',
          WITHDRAWN: 'withdrew the cancellation request.'
        },
        EXTERNAL_REFERENCE: {
          UPDATED: 'has made a modification in these fields:'
        },
        REPRESENTED: {
          UPDATED: 'has made a modification in counterparties:'
        },
        REQUEST: {
          ACCEPTED: 'accepted the negotiation request.',
          REJECTED: 'rejected the negotiation request.',
          SENT: 'sent a negotiation request.'
        }
      },
      ORDER: {
        CREATED: 'created the Order.',
        BROKER: {
          UPDATED: 'delegated the management of the Trade to another Company.'
        }
      },
      FIXATION_REQUEST: {
        UPDATED: 'Update the price or volume.',
        CREATED: 'Create a new request fixation.'
      },
      APPLICATION: {
        APPROVED_AMOUNT_UPDATED: 'modified the approved amount.',
        APPROVED: 'approved a step of the Application.',
        CREATED: 'started the Application process.',
        FORM: 'updated Application data.',
        GRANTED: 'granted the Application.',
        PRODUCT_UPDATED: 'modified properties of the Application Product.',
        REJECTED: 'rejected the Application.',
        REVISION: 'created a review of the Application.',
        UNDO: 'returned to a previous step of the Application.',
        UPDATED: 'made changes to the Application.',
        WITHDRAWN: 'withdrew the Application.'
      }
    }
  },
  MARKET_SUMMARY: {
    ZONES: 'Zones',
    LOCATIONS: 'Cities',
    DELIVERY_LOCATION: 'Delivery place',
    PURCHASE: 'Bid',
    SALE: 'Offer',
    LAST_NEGOTIATED: 'Last traded',
    LAST_NEGOTIATED_ABBR: 'Last traded',
    OFFERED_VOLUME: 'Volume offered (Offers)',
    OFFERED_VOLUME_ABBR: 'Vol.',
    DEFERRED: 'Deferred'
  },
  WORKING_ORDERS: {
    SUMMARIZE: 'Showing <span class="quantity">{{quantity}}</span> Order(s)',
    SELECTED: '<span class="quantity">{{quantity}}</span> Order(s) selected',
    BOOK: 'Book',
    SELECT_ALL: 'Select all',
    UNAUTHORIZED: 'Unauthorized',
    CONSIGNMENT_CONDITIONS: 'Consignment conditions',
    ARBITRATION_CHAMBER: 'Arbitration chamber',
    CONTRACT_DEVIATIONS: 'Deviations from standard contract',
    AUCTION_DATE: 'Date of the Auction',
    AUCTION_DURATION: 'Duration of the Auction',
    AUCTION_DATE_FORMAT: 'dd/MM/yy - HH:mm',
    MINUTES: 'minutes',
    BUYER_OPTION: 'Buyer\'s option',
    CHAT: 'Chat',
    VIEWMODE: {
      TABLE: 'Table',
      EXPANDED: 'Expanded',
      GALLERY: 'Gallery',
      MAP: 'Map'
    }
  },
  TEMPLATES_VIEW: {
    TITLE: 'Templates',
    NO_RECORDS: 'The Company doesn\'t have any Templates yet.',
    DESCRIPTION: 'Templates allow you to create Orders quickly and can be reused as many times as you want.',
    TABLE_HEADER: {
      DATE: 'Created at'
    },
    VIEW_TEMPLATE: 'View Template',
    CONSIGNMENT_CONDITIONS: 'Consignment conditions',
    ARBITRATION_CHAMBER: 'Arbitration chamber',
    CONTRACT_DEVIATIONS: 'Deviations from standard contract',
    DELETE_CONFIRM: {
      MODAL_TITLE: 'Delete Template',
      TITLE: 'Are you sure you want to remove this Template?'
    },
    DELETE: 'Delete',
    AUCTION_DATE: 'Date of the Auction',
    AUCTION_DURATION: 'Duration of the Auction',
    AUCTION_DATE_FORMAT: 'dd/MM/yy (HH:mm)',
    MINUTES: 'minutes'
  },
  FILTERS: {
    ACTIVE: 'Active',
    CASH: 'Cash against delivery',
    CLEAR: 'Clear filter',
    CLOSED_BARTER: 'Closed',
    CLOSED: 'Closed',
    COMMERCIAL_ZONE: 'Commercial zone',
    DELIVERY_RANGE: 'Delivery range',
    FIXATION_PERIOD: 'Fixation period',
    DELIVERY_ZONE: 'Delivery zone',
    ASSIGNMENT_RANGE: 'Assignment range',
    ENVELOPE_STATUS: 'Status',
    EXPIRED: 'Expired',
    FILTER_CONDITIONS: 'Filter conditions',
    FILTER: 'Filter',
    HIDE: 'Hide filters',
    INACTIVE: 'Inactive',
    LABELS: 'Labels',
    ON_DATE: 'On date',
    OPEN_BARTER: 'Open',
    OPEN: 'Open',
    OPERATION_TYPE: 'Operation type',
    ORDER_TYPE: 'Publication type',
    PRICE: 'Price',
    REMOVE_ALL: 'Remove all',
    SEE_ALL: 'See all...',
    SELECT_PERIOD: 'Select a period',
    SELECT_RANGE: 'Select range',
    SHOW: 'Show filters',
    TEXT_PLACEHOLDER: 'Text...',
    VALIDITY: 'Validity',
    WITH_CONTRACT: 'With Contract',
    WITHOUT_CONTRACT: 'Without Contract',
    SEARCH_CONTRACT: 'Search contract',
    ARCHIVED: 'Archived',
    VALID: 'Valid',
    FINTECH: {
      ACTIVE: 'Active',
      GRANTED: 'Granted',
      REJECTED: 'Rejected',
      WITHDRAWN: 'Withdrawn',
      EXPIRED: 'Expired'
    },
    CONDITIONS: {
      AFTER: 'After',
      BEFORE: 'Before',
      CONTAINS: 'Contains',
      ENDS: 'Ends with',
      EQUAL: 'Is equal to',
      FALSE: 'False',
      GREATER_THAN: 'Greater than',
      IS: 'Is exactly',
      LAST_MONTH: 'Last month',
      LAST_WEEK: 'Last week',
      LOWER_THAN: 'Lower than',
      STARTS: 'Starts with',
      NONE: 'None',
      NOT: 'Does not contain',
      TRUE: 'True',
      WHEN: 'Exact date',
      RANGE: 'Range'
    }
  },
  DOCUMENTS: {
    ENTER: 'Enter',
    RELATED_DOCS: 'Related documents',
    SELECT_PRODUCT: 'Select a product',
    SELECTED_MARKET: 'Market:'
  },
  INBOX: {
    DATE_FORMAT: 'dd/MM/yy, HH:mm',
    EMPTY_UNREAD: 'You don\'t have unread notifications.',
    EMPTY: 'You didn\'t get any message yet.',
    MARK_ALL_AS_READ: 'Mark all as read',
    MARK_AS_READ: 'Mark as read',
    MARK_AS_UNREAD: 'Mark as unread',
    NOTIFICATIONS: 'Notifications',
    SEE_ALL: 'See all...',
    TO: 'to <b>{{company}}</b>'
  },
  SUPER_ADMIN: {
    ACTIVATE_COMPANY_MODAL: {
      TITLE: 'Activation of Company {{company}}',
      BODY: 'Select the activation <b>level</b>.',
      LEVEL: 'Level',
      ACTIVATE_BUTTON: 'Modify activation'
    }
  },
  USER_PROFILE: {
    LANGUAGE: 'Language',
    NOTIFICATIONS: 'Notifications',
    PHONE: 'Cell phone',
    PROFILE_HELP: 'Keep your personal information updated.',
    PROFILE_TITLE: 'Personal information',
    PROFILE_UPDATE: 'Profile update',
    PROFILE_UPDATED: '<b>Profile updated!</b>',
    SECURITY_TITLE: 'Security',
    DELETE: {
      BUTTON: 'Delete account',
      ERROR: '<b>You don\'t have permissions</b>, contact our support team.',
      HELP: 'You are about to begin the process of deleting your account. You will lose access to the platform and the permissions you have been granted in different Companies.<br><b class="text-warning">This operation cannot be undone.</b>',
      TITLE: 'This action will delete your account.'
    },
    CHANNELS: {
      ENABLE: 'Specify through which channels you want to receive notifications.',
      HELP: 'Controls to make sure you receive the notifications that interest you.',
      TITLE: 'Channels'
    },
    PASSWORD: {
      ERROR: '<b>Wrong password</b>',
      HELP: 'Choose a unique password to protect your account. We recommend changing your access password periodically.',
      NEW: 'New Password',
      OLD: 'Current password',
      UPDATE: 'Change password',
      UPDATED: '<b>Password updated!</b>'
    }
  },
  CELLPHONE_VERIFICATION: {
    MODIFY: 'Modify number',
    ADD: 'Add number',
    MODAL: {
      TITLE: 'Cellphone setup',
      COUNTRY_CODE: 'Country code',
      AREA_CODE: 'Area code',
      SELECT_CODE: 'Select a country code',
      NUMBER_HELP: 'Country code + Area code + Nº. Example: +54 (911) 4323-4556',
      VERIFICATION_DESCRIPTION: 'We\'ve set you a text message with a verification code. If you haven\'t received it after a few minutes,',
      SEND_AGAIN: 'click here to send it again',
      NEXT: 'Next',
      ERROR: 'The verification code is incorrect.',
      CODE_FROM_TEXT_MESSAGE: 'Verification code',
      VALIDATING: 'Validating code...',
      ALLOW_WHATSAPP: 'I agree to receive notifications over <b>WhatsApp</b>',
      ALLOW_WHATSAPP_HELP: 'If the service is available, the platform will notify the news through the <b>WhatsApp</b> application, this includes the possibility of carrying out some actions without having to enter the platform. Otherwise, they will be done via SMS.'
    }
  },
  PAGINATOR: {
    FIRST_PAGE: 'First page',
    LAST_PAGE: 'Last page',
    NEXT_PAGE: 'Next page',
    OF: 'of',
    PREV_PAGE: 'Previous page',
    SUMMARY: 'Page {{page}} of {{total}}'
  },
  COUNTDOWN: {
    DAY: 'Day(s)',
    HOUR: 'Hour(s)',
    MINUTE: 'Minute(s)',
    SECOND: 'Second(s)',
    DAY_ABBR: 'd',
    HOUR_ABBR: 'h',
    MINUTE_ABBR: 'm',
    SECOND_ABBR: 's',
    EXPIRED: 'Expired'
  },
  PRICE_FORM: {
    FIXED: 'Fixed',
    PREMIUM: 'Basis',
    TO_BE_FIXED: {
      LABEL: 'To be fixed',
      CONDITIONS: 'Fixing conditions:',
      PLACEHOLDER: 'Enter fixing conditions...'
    },
    CONSIGNMENT: {
      LABEL: 'Consignment',
      CONDITIONS: 'Consignment conditions:',
      PLACEHOLDER: 'Enter consignment conditions...'
    }
  },
  QUALITY_INPUT: {
    TITLE: 'Edit parameters',
    ACTION: 'Apply changes'
  },
  ASSIGNEES_MANAGER: {
    ADD_ME_BODY: 'Do you want to receive notifications every time there is news? You can unsubscribe at any time.',
    ADD_ME: 'Subscribe me',
    CREATOR: 'Creator',
    RESPONSIBLE: 'Operator(s)',
    WATCHERS: 'Watcher(s)',
    MODAL: {
      CLOSE: 'Close',
      PLACEHOLDER: 'Enter the User\'s name or e-mail...',
      RESPONSIBLE_HELP: 'Users of your Company responsible for this entity. There must be at least one assigned.',
      RESPONSIBLE: 'Operator(s)',
      TITLE: 'Assignees',
      WATCHERS_HELP: 'Users of your Company that will be notified about developments in this entity.',
      WATCHERS: 'Watcher(s)'
    }
  },
  COMMERCIAL_MANAGER: {
    TITLE: 'Commercial team management',
    DESCRIPTION: 'Set the products and buying volume limits for each commercial User of your Company. Check their daily, weekly and monthly performance.',
    BUY_TRADES: 'Buy trades',
    SELL_TRADES: 'Sell trades',
    NO_TEAM: 'Your Company does not have a commercial team.',
    TABLE: {
      NAME_LASTNAME: 'Name and last name',
      PRODUCTS: 'Products',
      DAILY_STOP: 'Daily limit',
      TODAY_BOUGHT: 'Today bought trades',
      WEEKLY_STOP: 'Weekly limit',
      WEEK_BOUGHT: 'Week bought trades',
      MONTHLY_STOP: 'Monthly limit',
      MONTH_BOUGHT: 'Month bought trades',
      TOTAL_BOUGHT: 'Total bought trades',
      EDIT_PRODUCTS: 'Edit products...',
      IN_TRADES_1: 'in',
      IN_TRADES_2: 'trade(s).'
    },
    MODAL: {
      TITLE: 'Select products for:',
      DESCRIPTION: 'You can define one or more. Leave this field empty to assign all the products.',
      PLACEHOLDER: 'Enter the product name...'
    }
  },
  MESSENGER: {
    LAUNCHER_TITLE: 'Start a conversation...',
    UNREAD_BULLET: 'You have unread messages',
    ONLINE: 'Online',
    OFFLINE: 'Offline',
    TYPING: 'Typing...',
    TRACK_RECORD: 'The messages will be available in the track record.',
    PLACEHOLDER: 'Type a message and press Enter...',
    DROP_HERE: 'Drop your files here...',
    ATTACH: 'Attach...',
    UPLOADING: 'Uploading...',
    TODAY: 'Today'
  },
  OPERATION_ALERTS: {
    TITLE: 'Operations alerts',
    ADD: 'Add alert',
    DESCRIPTION: 'Define the criteria of the alerts for which you want to receive notifications.',
    NO_ALERTS: 'No alerts defined.',
    DELETE: {
      ACTION: 'Delete alert',
      TITLE: 'Are you sure you want to delete this alert?'
    },
    TABLE: {
      ACTIVE: 'Active',
      PRICE_RANGE: 'Price range'
    },
    TYPE: {
      INDISTINCT: 'Indistinct'
    },
    MODAL: {
      TITLE: 'Add alert',
      DESCRIPTION: 'You will receive a notification when a new post meets these requirements:',
      OPERATION_TYPE: 'Type of operation',
      DELIVERY_PLACEHOLDER: 'Enter one or more places...',
      PLACES_HELP: 'Leave this field empty if it is indistinct.',
      DATE_HELP: 'Leave this field empty if it is indistinct.'
    }
  },
  MESSENGER_CENTER: {
    DATE_FORMAT: 'd/M/yy',
    EMPTY: 'You haven\'t sent or received messages yet',
    NO_RESULTS: 'No contact or message found',
    SEARCH_PLACEHOLDER: 'Search...',
    SELECT_ROOM: 'Select a room to chat',
    TITLE: 'Chat center'
  },
  ADD_PHONE: {
    MESSAGE: '<i class="material-symbols-rounded">smartphone</i> Add your cell phone to receive <b>SMS notifications</b>!'
  },
  PAYMENT_CONDITION_INPUT: {
    TITLE: 'Edit payment terms',
    SELECT_TERMS: 'Select a term',
    OTHER_TERM: 'Alternative term'
  },
  GOOGLE_PLACES: {
    PLACEHOLDER: 'Search places...'
  },
  ERROR_LIST: {
    BSDATE: 'The date is not valid',
    COMPANY_EXISTS: 'A Company with this fiscal ID already exists, ask the Administrator to <b>join the team</b>',
    COMPANY_NOT_ACTIVE: 'The Company is not active',
    EMAIL_OT_FOUND: 'The e-mail entered is not registered',
    EMAIL: 'Invalid E-mail',
    FILE_MAX_SIZE: 'The file cannot have more than {{max}}',
    FILE_TYPE: 'Only the following file types are supported:',
    FUTURE_TIME: 'The time entered cannot be earlier than the current time',
    MATCHES: 'Password and confirmation do not match',
    MAX_FILES: 'Maximum number of files allowed: {{max}}',
    MAX_LENGTH: 'The maximum number of characters is {{max}}',
    MAX: 'Maximum value allowed: {{max}}',
    MIN_LENGTH: 'The minimum number of characters is {{min}}',
    MIN: 'Minimum value allowed: {{min}}',
    PATTERN: 'Doesn\'t accomplish the requirements',
    REQUIRED: 'This field is required',
    TIME_RANGE: 'The selected time must be in the following range:',
    UNIQUE: 'E-mail already used',
    VALID_CUIT: 'The tax ID entered is not valid',
    VALID_ID: 'The identity code entered is not valid',
    WAYBILL_EXISTS: 'This number has already been used'
  },
  PRICE_DISPLAY: {
    TO_BE_FIXED: 'To be fixed',
    TO_BE_FIXED_ABBR: 'NPE',
    CONSIGNMENT: 'Consignment',
    CONSIGNMENT_ABBR: 'Consig.',
    CONSIGNMENT_CONDITIONS: 'Consignment conditions:'
  },
  SLOTS: {
    ACCEPT_AND_ASSIGN: 'Accept and assign all to my Company...',
    ACCEPTED: 'Accepted',
    ACTORS: 'Actors',
    ADD: 'Add Slots',
    ARRIVAL_DATE: 'Arrival Date',
    ASSIGN: 'Assign',
    ASSIGNED_BY_TO: '<b>{{allocator}}</b> assigned the following Slots to <b>{{company}}</b>.',
    ASSIGNED_BY: '<b>{{allocator}}</b> assigned the following Slots.',
    ASSIGNED_QUANTITY: '{{quantity}} assigned',
    ASSIGNED_TRADE: 'This Trade has a total of <b>{{quantity}} Slot(s)</b> assigned.',
    ASSIGNED: 'Assigned',
    AVAILABLE_BATCHES: '<b>{{product}}</b> batches with Slots pending assignment:',
    BATCH_DETAILS: 'Batch details',
    BATCH_ID: 'Slots batch #{{id}}',
    BATCH: 'Slots batch',
    CONDITIONAL: 'Conditional',
    CONFORMING: 'Conforming',
    CREATED: 'Created',
    DELETE_BODY: 'You\'re about to delete the Slot <code>{{slot}}</code>. <span class="text-muted">If it had already been assigned, it will be withdrawn.</span><br><br>This action cannot be undone.',
    DOWNLOAD_PDF: 'Download PDF',
    EDIT_ACTORS: 'Edit participants...',
    ESTABLISHMENT_HELP: 'In case of having a storage plant.',
    ESTABLISHMENT: 'Establishment',
    ESTIMATED: 'Estimated',
    EXPORT_PERIOD: 'You can specify a period of up to 30 days to include in the report.',
    FREIGHT_PAYED: 'Freight paid',
    FREIGHT_PAYER: 'Freight payer',
    FREIGHT_TO_PAY: 'Freight to pay',
    FROM_ASSIGNED: 'Out of <b>{{slots}}</b> assigned.',
    FROM_SENT: 'Out of <b>{{slots}}</b> sent.',
    GRAINS: 'Grains/Species',
    ID: 'Slot ID',
    KM_TO_TRAVEL: 'Kilometers to travel',
    LOAD: 'Load',
    LOADING_DATE: 'Loading date',
    LOCALITY: 'Locality',
    MANAGED_BY: 'Batch managed by:',
    NO_RECORDS: 'No Slots registered',
    ORIGIN: 'Origin of the product',
    OVERALLOCATION_WARNING_ASSIGNED: 'This Trade already has <b>{{ assigned }}</b> assigned Slot(s).',
    OVERALLOCATION_WARNING: 'Warning of potential overallocation, for a Trade of <b>{{tons}}</b> tons.',
    PENDING: 'Waiting answer',
    PROVINCE: 'Province',
    QUALITY_DECLARATION: 'Quality declaration',
    RECEIVED: 'Received pending',
    REFRESH_STATUS_STOP: 'Update status',
    REJECTED: 'Rejected',
    RENSPA: 'R.E.N.S.P.A.',
    RETURN_BODY: 'You\'re about to return the Slot <code>{{slot}}</code> to <b>{{allocator}}</b>, are you sure?. This means that you won\'t use it <span class="text-muted">(if the Slot had already been assigned to a third party, it will be withdrawn)</span>.<br><br>This action cannot be undone.',
    RETURN: 'Return Slot',
    RETURNED: 'Returned',
    TAKE: 'Request/Take',
    TOGGLE_DASHBOARD: 'Toggle Dashboard',
    UNAVAILABLE_BATCHES: 'No <b>{{product}}</b> batches with Slots pending to be assigned found.',
    VEHICLE_DATA: 'Vehicle data',
    WAYBILL_FILE_HELP: 'Only PDF files are accepted (Max.: 4 MB).',
    WAYBILL_FILE: 'Blank Waybill',
    WAYBILL_GENERATE: 'Generate Waybill',
    WAYBILL_GENERATOR_DESCRIPTION: 'Make the Waybill for trucks by filling in the following form.',
    WAYBILL_GENERATOR: 'Waybill generator',
    WAYBILL_INFO: 'Waybill information',
    WEIGHED_DESTINATION: 'The load will be weighed at destination',
    WITHDRAWN: 'Withdrawn',
    YES_RETURN: 'Yes, return',
    RETURN_BATCH: 'Return all Slots',
    RETURN_BATCH_BODY: 'You\'re about to return <b>{{ quantity }} Slot(s)</b> to <b>{{ allocator }}</b>, are you sure?. This means that you will not use them <span class="text-muted">(if any Slot has already been assigned to a third party, it will be withdrawn)</span>.<br><br>This action cannot be undone.',
    RETURN_SELECTED: 'Return selected slots',
    DELETE_BATCH: 'Delete all slots',
    DELETE_BATCH_BODY: 'You\'re about to delete <b>{{ quantity }} Slot(s)</b>, are you sure?.<br><br>Please note that only unassigned slots will be eliminated.',
    DELETE_SELECTED: 'Delete selected slots',
    // REFRESH_WAIT: 'Wait',
    ALREADY_EXISTS: 'Watch out! The following slots already exist within the system:',
    STOP: {
      DESCRIPTION: 'When available, The platform can display <a href="https://www.ssta.gob.ar/turnos/" target="_blank">STOP system</a> information. For this, it is necessary that you provide us with the API KEY linked to your Company fiscal id.',
      HELP: 'We won\'t share this key with third parties.',
      LABEL: 'STOP API key',
      LINK: 'Integrate with STOP',
      NOT_CONNECTED: 'In order to import information it is necessary to provide an API key.',
      TITLE: 'API KEY Slots system (STOP)'
    },
    ACTIONS: {
      ACCEPTED_BATCH_PARTIALLY: 'accepted parcially the Slots batch.',
      ACCEPTED_BATCH: 'accepted the Slots batch.',
      ASSIGNED_SLOT: 'assigns the Slot to his Company.',
      CREATE_BATCH: 'generated the Slots batch.',
      REJECTED_BATCH: 'rejected the Slots batch.',
      RETURNED_BATCH: 'returned the Slot.',
      SENT_BATCH: 'forwarded the Slots batch to <b>{{company}}</b>.',
      WITHDREW_BATCH_PARTIALLY: 'withdrew parcially the Slots batch.',
      WITHDREW_BATCH: 'withdrew the Slots batch.'
    },
    TABLE_HEADER: {
      ASSIGNED_BY: 'Assigned by',
      ASSIGNED_SLOTS: 'Assigned',
      ASSIGNED_TO: 'Assigned to',
      BATCH_SLOTS: 'Slots',
      PENDING_SLOTS: 'Pending',
      PRODUCT_ABBR: 'Prod.',
      RECEIVER: 'Receiver / Destination',
      UNLOADED_SLOTS: 'Unloaded'
    },
    STATUS: {
      ACCEPTED: 'Accepted',
      EXPIRED: 'Expired',
      SENT: 'Sent',
      RECEIVED: 'Received',
      REJECTED: 'Rejected',
      WITHDRAWN: 'Withdrawn',
      RETURNED: 'Returned',
      ASSIGNED: 'Assigned',
      ASSIGNED_PARTIALLY: 'Assigned partially',
      PENDING: 'Pending',
      STOP: {
        '1': 'Without CTG',
        '2': 'Activated',
        '3': 'Unloaded',
        '5': 'Arrived',
        '99': 'N/A'
      }
    },
    ACCEPT_MODAL: {
      TITLE: 'Slots Acceptance',
      DESTINATION: 'Destination',
      SLOTS_TO_ACCEPT: 'Number of Slots to accept:',
      REASONS: 'Reasons',
      REASONS_PLACEHOLDER: 'Enter the reasons for not accepting the total (optional)...',
      SIMPLER_QUESTION: 'Do you want to accept all the Slots and assign them to your Company?',
      SIMPLER_QUESTION_NO: 'No, I want to accept fewer Slots...',
      SIMPLER_QUESTION_YES: 'Yes, <b>{{company}}</b> will be the holder of the Waybills',
      ACCEPT: 'Accept {{quantity}} Slot(s)'
    },
    ASSIGN_MODAL: {
      AVAILABLE_SLOTS: 'Slots available in this batch: <b>{{quantity}}</b>',
      DIRECT: 'Direct',
      ENTER_ANOTHER: 'Enter another...',
      NO_COMPANIES: 'There are no other available Companies.',
      QUANTITY: 'Number of Slots',
      PENDING_SLOTS: 'Pending Slots: <b>{{quantity}}</b>',
      OBSERVATIONS_HELP: 'Recipients of these batches will receive these same observations.',
      OBSERVATIONS_PLACEHOLDER: 'Instructions, comments...',
      TITLE: 'Slots assigment',
      TAKE: 'Atribuir à minha Empresa',
      TAKE_HELP: 'By assigning these Slots to your Company means that <b>{{company}}</b> will be the holder of the Waybills.'
    },
    REQUEST_SLOT_MODAL: {
      AVAILABLE_SLOTS: 'Slots available to request: <b>{{quantity}}</b>',
      MAX_SLOTS: 'Maximum number of Slots to request: <b>{{quantity}}</b>',
      APPLIED_VOLUME: 'Applied volume',
      ENTER_ANOTHER: 'Enter another...',
      NO_COMPANIES: 'No hay otras Empresas disponibles.',
      QUANTITY: 'Number of Slots',
      TITLE: 'Request Slots',
      REQUESTED: 'Slots Requested',
      APPLICANT: 'Applicant',
      RECIPIENT: 'Recipient',
      EXISTING_REQUEST_MESSAGE: 'There are <strong>{{ quantity }}</strong> requests associated with this negotiation. With a total of <strong>{{ total }}</strong> slots requested.',
      EXISTING_REQUEST_MESSAGE_CONTRACT: 'There are <strong>{{ quantity }}</strong> requests associated with this contract. With a total of <strong>{{ total }}</strong> slots requested.'
    },
    REJECT_MODAL: {
      TITLE: 'Reject Slots',
      DESCRIPTION: 'Optionally, you can include the reasons for rejection:',
      PLACEHOLDER: 'Enter the reasons...',
      REJECT: 'Reject Slots'
    },
    WITHDRAW_MODAL: {
      TITLE: 'Do want to withdraw this batch?',
      DESCRIPTION: 'Optionally, you can include the reasons for this withdrawal. So that <b>{{Company}}</b> can see them:'
    },
    ADD_MODAL: {
      ADDRESS: 'Address',
      TITLE: 'Add Slots',
      DESTINATION: 'Destination',
      DESTINATION_DETAIL: 'Facility / Port / Terminal',
      DESTINATION_DETAIL_HELP: 'Include all the necessary data to identify the destination.',
      DESTINATION_DETAIL_PLACEHOLDER: 'Enter the details...',
      SLOT_IDS_HELP: 'Enter an alphanumeric code per line',
      ENTER_DESTINATION: 'Enter a destination',
      DESTINATION_NOT_LISTED: 'Can\'t find the Destination?',
      RECEIVER_NOT_LISTED: 'Can\'t find the Recipient?'
    },
    DETAILS_MODAL: {
      TITLE: 'Slots details',
      HOLDER: 'Holder',
      NUMBER: 'Number',
      CTG: 'Grain Traceability Code',
      CTG_ABBR: 'G.T.C.',
      REQUEST_CTG: 'Request G.T.C.',
      CHECK_STATUS: 'Check status',
      WITHDRAW_SLOTS: 'Optionally, you can share the reasons for the withdrawal of these <b>{{quantity}}</b> Slots:'
    },
    VIEWMODE: {
      COMPACT: 'Compact',
      NORMAL: 'Normal'
    },
    REJECT_REQUEST_MODAL: {
      TITLE: 'Reject slots request',
      DESCRIPTION: 'Are you sure you want to reject this slots request?',
    },
    WITHDRAW_REQUEST_MODAL: {
      TITLE: 'Withdraw request for slots',
      DESCRIPTION: 'Are you sure you want to withdraw this slots request?',
      SUBMIT: '',
    },
    DELETE_REQUEST_MODAL: {
      TITLE: 'Are you sure to delete this quota request?',
    },
    IMPORT: {
      BUTTON: 'Import STOP status',
      DESCRIPTION: 'Update the STOP status of your slots by means of an Excel file <code>(.xls, .xlsx)</code>, you can download a sample template by clicking this <a href="/assets/docs/format_slots.xlsx" target="_blank" download=""><u>link</u></a>',
    }
  },
  BARTERS: {
    ADD_MANUAL: 'Manual loading',
    ADD_PRODUCT_HELP: '<b>The fields below are OPTIONAL</b>. Just complete the information you consider relevant.',
    ADD_PRODUCT: 'Add product',
    AMOUNT: 'Amount',
    BALANCE: 'Balance',
    BARTER_PROPOSAL_ID: 'Barter Proposal #{{id}}',
    BARTER_PROPOSAL: 'Barter Proposal',
    BROKERS: 'Brokers',
    CREATE_AND_SEND: 'Create and Send',
    CREATE: 'Create Proposal',
    DUE_ABBR: 'Due',
    EDIT_PRODUCT: 'Edit product',
    EXCLUDED_COMPANIES: 'The following Companies are excluded because they have active Proposals: <b>{{companies}}</b>.',
    EXEMPT: 'Exempt',
    INVOICE_NUMBER_HELP: 'You can specify all the invoices to be canceled with this barter operation, and even define partial amounts.',
    MANAGERS: 'Managers',
    MORE_DETAILS: 'This requires more details',
    NEW_PROPOSAL: 'New Barter proposal',
    NO_CUSTOMER: '<b>There are no Customers in your Network.</b> To add Companies <a class="alert-link" href="/company/{{companyId}}/network?order_by=-verification_date">click here</a>.',
    NO_DISTRIBUTOR: '<b>There are no Distributors in your Network.</b> To add Companies <a class="alert-link" href="/company/{{companyId}}/network?order_by=-verification_date">click here</a>.',
    NO_RECORDS: 'No Barters are registered for this Company.',
    NO_SUPPLIER: '<b>There are no Suppliers in your Network.</b> To add Companies <a class="alert-link" href="/company/{{companyId}}/network?order_by=-verification_date">click here</a>.',
    NO_TAX: '{{tax}} not included',
    NUMBER_PROPOSALS: '<b>{{quantity}}</b> proposal(s)',
    OFFLINE: 'Offline Barter',
    OPTIONAL_PROPOSALS: 'You can add one or more products to barter (specifying grain, quality, delivery period, origin).',
    PARTIAL: 'Partial',
    REPLICATE: 'Select a market Order to replicate its conditions:',
    SELF_MANAGED_HELP: 'This Barter will be managed by <b>{{name}}</b>, without intermediaries.',
    SEND_NEW: 'Send new proposal...',
    SEND: 'Send proposal',
    SUMMARY: 'Showing <span class="quantity">{{barters}}</span> Barter(s) and their <span class="quantity">{{proposals}}</span> Proposal(s).',
    TO_BE_SETTLED: 'Amount to cancel',
    TOTAL: 'Total invoice',
    ACCEPT_CONFIRM: {
      BODY: '<p>By accepting the Barter terms, the confirmation and closure will be in charge of <b>{{company}}</b>.</p><div class="text-danger"><b>Important:</b> Acceptance is a commitment. Once done IT CANNOT BE WITHDRAWN.</div>',
      TITLE: 'Do you accept the terms?'
    },
    REJECT_MODAL: {
      TITLE: 'Do you want to reject this Barter proposal?',
      BODY: 'This means that you are not interested in moving forward this Barter. This action cannot be undone.'
    },
    REMOVE_MODAL: {
      TITLE: 'Do you want to remove this product from the Proposal?',
      BODY: 'If you want to include it later, you will have to add it again.'
    },
    STATUS: {
      ACCEPTED_LONG: '<b>Proposal accepted.</b> The deals are confirmed.',
      ACCEPTED: 'Proposal accepted',
      IN_PROGRESS_LONG: '<b>Proposal under negotiation.</b> Waiting for the counterparties.',
      IN_PROGRESS: 'Proposal under negotiation',
      NEW_LONG: '<i class="material-symbols-rounded blinking">warning</i> <b>New proposal.</b> Review and/or complete the necessary information.',
      NEW: 'New proposal',
      RECEIVED_LONG: '<i class="material-symbols-rounded blinking">warning</i> <b>Proposal received.</b> You must reply.',
      RECEIVED: 'Proposal received',
      REJECTED_LONG: '<b>Proposal rejected.</b>',
      REJECTED: 'Proposal rejected',
      SENT_LONG: '<b>Proposal sent.</b> Waiting for counterparty to reply.',
      SENT: 'Proposal sent'
    },
    WITHDRAW_MODAL: {
      TITLE: 'Do you want to withdraw this Barter proposal?',
      BODY: 'This means that you are not interested in moving forward this Barter. This action cannot be undone.'
    }
  },
  TRUCKS: {
    APPLIED: 'Applied',
    DESTINATION: 'Destination',
    DESTINATION_WEIGHT: 'Destination weight',
    FEE: 'Rate',
    FEE_REFERENCE: 'Reference rate',
    GROSS: 'Gross',
    GROSS_KG: 'Gross: {{quantity}} <small>Kgs.</small>',
    NET: 'Net',
    NET_KG: 'Net: {{quantity}} <small>Kgs.</small>',
    NO_DATA: 'No trucks recorded.',
    ORIGIN_WEIGHT: 'Origin weight',
    SUMMARIZE: 'Showing <span class="quantity">{{quantity}}</span> Unload(s)',
    TARE: 'Tare',
    TARE_KG: 'Tare: {{quantity}} <small>Kgs.</small>',
    TITLE: 'Unloads',
    TO_COVER: 'To cover',
    TRAILER: 'Trailer',
    TRANSPORT: 'Transport',
    TRUCK: 'Truck',
    UNLOADED: 'Unloaded',
    UNLOADS_NUMBER: 'in {{quantity}} truck(s)',
    WASTAGE: 'Wastage',
    WAYBILL: 'Waybill',
    ACTORS: {
      BUYING_BROKER: 'Buying broker',
      CARRIER: 'Carrier',
      CARRIER_BROKER: 'Carrier broker',
      COMMERCIAL_SENDER: 'Commercial sender',
      DESTINATION: 'Destination',
      DRIVER: 'Driver',
      HOLDER: 'Holder',
      INTERMEDIARY: 'Itermediary',
      RECEIVER: 'Receiver',
      REPRESENTATIVE: 'Representative/Deliver',
      SELLING_BROKER: 'Selling broker',
      TERM_MARKET: 'Term market'
    },
    CPE_ACTORS: {
      TITULAR_CARTA_DE_PORTE: 'Electronic waybill holder',
      REMITENTE_COMERCIAL_PRODUCTOR: 'Commercial Sender Producer',
      REMITENTE_COMERCIAL_VENTA_PRIMARIA: 'Commercial Sender Primary Sale',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA: 'Commercial Sender Secondary Sale',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA_2: 'Commercial Sender Secondary Sale 2',
      MERCADO_A_TERMINO: 'Term market',
      CORREDOR_VENTA_PRIMARIA: 'Broker Primary Sale',
      CORREDOR_VENTA_SECUNDARIA: 'Broker Secondary Sale',
      REPRESENTANTE_ENTREGADOR: 'Representative/Deliver',
      REPRESENTANTE_RECIBIDOR: 'Representative/Receiver',
      DESTINATARIO: 'Receiver',
      DESTINO: 'Destination',
      EMPRESA_TRANSPORTISTA: 'Transport Company',
      FLETE_PAGADOR: 'Freight Payer',
      CHOFER: 'Driver',
      INTERMEDIARIO_DE_FLETE: 'Freight Intermediary'
    },
    WASTAGE_ITEMS: {
      DAMAGED: 'Damaged grains',
      FOREIGN_BODIES: 'Foreign bodies',
      HUMIDITY: 'Humidity',
      OTHER: 'Others',
      TOTAL: 'Total wastage',
      WEED: 'Weeds'
    }
  },
  INVOICES: {
    ADD_COVERAGE: 'Incorporate other coverage concepts:',
    BALANCE: 'Balance',
    BILLING_IN: '{{unit}} billing',
    CANCEL_BARTER: 'Cancel with a Barter?...',
    CONCEPT_ABBR: 'Con.',
    CONCEPT: 'Concept',
    COVER_BARTER: 'Cover with a Barter',
    COVERAGE: 'Coverage',
    COVERED_TOTAL: 'Covered',
    COVERED: 'Covered',
    CREATE: 'Upload new invoice',
    DESCRIPTION: 'Description',
    EDIT_COVERAGE: 'Edit coverage',
    IMPORT: 'Import Invoices',
    INVOICES_IN: 'Invoices in {{unit}}',
    INVOICES_NUMBER: 'in {{quantity}} invoice(s)',
    ISSUED_TO: 'Issued to',
    NET: 'Net',
    NO_DATA: 'No invoices recorded',
    NOT_LISTED: 'Can\'t find the invoice?',
    PARTIALLY_COVERED: 'Partially covered',
    PERCENTAJE_ABBR: 'Por.',
    SUMMARIZE: 'Showing <span class="quantity">{{quantity}}</span> Invoice(s)',
    TAXED: 'Taxed',
    TITLE: 'Invoices',
    IMPORTED_BY: 'Imported by'
  },
  FIXATIONS: {
    DELETE: 'Are you sure to delete this fixation?',
    SUMMARIZE: 'Showing <span class="quantity">{{quantity}}</span> Fixation(s)',
    TITLE: 'Fixations',
    REQUEST_MODAL: {
      TITLE: 'Send fixation request',
      MANAGE: 'Manage fixation requests',
      REQUEST_FIXATIONS: 'Request Fixations',
      REQUEST: 'Request',
      REPLY: 'Reply',
      OBSERVATIONS_HELP: 'The receivers of this request will receive these same observations.',
      LAST_OBSERBATION: 'Last observation:',
      ALERT_LIMIT_OF_VOLUME: 'It exceeds the number of tons that can be requested for this contract.'
    },
    REQUEST_STATUS: {
      WAITING_RECEIPT: 'Waiting Response',
      WAITING_COMPANY: 'Waiting Response',
      WAITING_COUNTERPART: 'Waiting Counterpart',
      ACCEPTED: 'Accepted',
      REJECTED: 'Rejected',
    }
  },
  WAYBILLS: {
    NO_DATA: 'There are no Waybills to show.',
    SUMMARIZE: 'Showing <span class="quantity">{{quantity}}</span> Waybill(s)',
    TITLE: 'Waybills',
    ACTORS: {
      CHOFER: 'Driver',
      CORREDOR_VENTA_PRIMARIA: 'Corredor Venta Primaria',
      CORREDOR_VENTA_SECUNDARIA: 'Corredor Venta Secundaria',
      DESTINATARIO: 'Receiver',
      DESTINO: 'Destination',
      EMPRESA_TRANSPORTISTA: 'Transport Company',
      FLETE_PAGADOR: 'Flete pagador',
      INTERMEDIARIO_DE_FLETE: 'Intermediario de flete',
      MERCADO_A_TERMINO: 'Mercado a Término',
      REMITENTE_COMERCIAL_PRODUCTOR: 'Remitente Comercial Productor',
      REMITENTE_COMERCIAL_VENTA_PRIMARIA: 'Rte. Comercial Venta Primaria',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA_2: 'Rte. Comercial Venta Secundaria 2',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA: 'Rte. Comercial Venta Secundaria',
      REPRESENTANTE_ENTREGADOR: 'Representante entregador',
      REPRESENTANTE_RECIBIDOR: 'Representante recibidor',
      TITULAR_CARTA_DE_PORTE: 'Waybill Holder',
      OBSERVACIONES: 'Observations'
    }
  },
  CPE: {
    NO_DATA: 'There are no Carta de Porte Electrónica to show.',
    SUMMARIZE: 'Showing <span class="quantity">{{quantity}}</span> Carta de Porte Electrónica(s)',
    TITLE: 'Carta de Porte Electrónica',
    ACTORS: {
      CHOFER: 'Driver',
      CORREDOR_VENTA_PRIMARIA: 'Corredor Venta Primaria',
      CORREDOR_VENTA_SECUNDARIA: 'Corredor Venta Secundaria',
      DESTINATARIO: 'Receiver',
      DESTINO: 'Destination',
      EMPRESA_TRANSPORTISTA: 'Transport Company',
      FLETE_PAGADOR: 'Flete pagador',
      INTERMEDIARIO_DE_FLETE: 'Intermediario de flete',
      MERCADO_A_TERMINO: 'Mercado a Término',
      REMITENTE_COMERCIAL_PRODUCTOR: 'Remitente Comercial Productor',
      REMITENTE_COMERCIAL_VENTA_PRIMARIA: 'Rte. Comercial Venta Primaria',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA_2: 'Rte. Comercial Venta Secundaria 2',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA: 'Rte. Comercial Venta Secundaria',
      REPRESENTANTE_ENTREGADOR: 'Representante entregador',
      REPRESENTANTE_RECIBIDOR: 'Representante recibidor',
      TITULAR_CARTA_DE_PORTE: 'Carta de Porte Electrónica'
    },
    TRANSPORTE: {
      TITLE: 'Datos del Transporte',
      TRANSPORTISTA_CUIT: 'Transportista CUIT',
      CHOFER_CUIT: 'Chofer CUIT',
      DOMINIO: 'Dominio',
      FECHA_PARTIDA: 'Fecha de Partida',
      KMS_RECORRER: 'Kms a Recorrer',
      TARIFA_REF: 'Tarifa Referencia',
      TARIFA: 'Tarifa',
    },
    ORIGIN: {
      PLANTA: 'Plant'
    }
  },
  LANDING: {
    MAIN: 'Start working in an agile and secure way, with traceability and sustainability.',
    SECONDARY: 'Welcome to the platform that<br>facilitates access to credit.',
    TAGLINE: '<span class="brand-color">Evolving</span> together',
    COMMUNITY: 'Community',
    MANAGEMENT: 'Management',
    FUNDING: 'Funding',
    food: {
      HEADLINE: '<b>Welcome</b><br>to the digital wholesale market to buy and sell food!',
      FEATURE_01: 'Reach more customers',
      FEATURE_02: 'Whenever and wherever you want',
      FEATURE_03: 'Easy and secure'
    },
    ar: {
      HEADLINE: '<b>Welcome</b><br>to the platform that allows you to trade grains and by-products.',
      FEATURE_01: 'Whenever and wherever you want',
      FEATURE_02: 'We integrate the entire commercial chain',
      FEATURE_03: 'Easy and secure'
    },
    br: {
      HEADLINE: '<b>Welcome</b><br>to the platform that allows you to trade grains and by-products.',
      FEATURE_01: 'Whenever and wherever you want',
      FEATURE_02: 'We integrate the entire commercial chain',
      FEATURE_03: 'Easy and secure'
    },
    pt: {
      HEADLINE: '<b>Welcome</b><br>to the platform that allows you to trade grains and by-products.',
      FEATURE_01: 'Whenever and wherever you want',
      FEATURE_02: 'We integrate the entire commercial chain',
      FEATURE_03: 'Easy and secure'
    }
  },
  IMPORTER: {
    ACCEPTED_FORMATS: 'The supported formats are:',
    ADD_CONTRACT: 'Add Contract',
    CONTRACT_NUMBER_HELP: 'Contract unique identifier.',
    CONTRACT_NUMBER: 'Contract number',
    DETAILS: 'Details',
    DROP_HELP: 'You can also drop files in this area to upload new content.',
    EDITING_CONTRACT: 'Editing Contract: {{id}}',
    ERROR_INDEX: '(Error: {{index}})',
    ERROR: 'Error',
    ERRORS_SUMMARY: 'Unable to import file(s)! <span class="quantity">{{files}}</span> error(s) in <span class="quantity">{{errors}}</span> file(s).',
    ERRORS: '({{errors}} error(s))',
    HIDE_FILE_DETAILS: 'Hide file details',
    INPUT: 'Input',
    INVALID_FILETYPE: "The uploaded file type is invalid.",
    LINE: 'Line',
    NO_COMPANIES: '<b>There are no Companies in your Network.</b> <a class="alert-link" href="/company/{{companyId}}/network?order_by=-verification_date">Click here to add</a>.',
    NOT_INPUT_AVAILABLE: "Not available",
    SELECT_FILES: 'Select the files you want to import:',
    SHOW_ALL_FIELDS: 'Show all fields',
    SHOW_ERROR_FIELDS: 'Show fields with errors',
    SHOW_FILE_DETAILS: 'Show file details',
    SHOW_MORE_ERRORS: 'Show more errors for this file...',
    SOURCE_FILE: 'Source file',
    STATUSES: 'Status of imports',
    TITLE: '<span class="material-symbols-rounded">cloud_upload</span> Import...',
    TRY_AGAIN: 'Try again...',
    UPDATED: 'Updated',
    VALUE: 'Value',
    WARNING: {
      CONTRACT_APPLIED_VOLUME_GT_110: 'The applied volume is greater than 110% of the total',
      CONTRACT_FIXED_VOLUME_GT_110: 'The fixed volume is greater than 110% of the total',
      CONTRACT_INVOICED_VOLUME_GT_APPLIED: 'The settled volume is greater than the applied',
      CONTRACT_INVOICED_VOLUME_GT_FIXED: 'The settled volume is greater than the fixed volume',
      CONTRACT_PRICE_LT_100_USD: 'The price is less than USD 100',
      CONTRACT_PRICE_LT_10000_ARS: 'Price is less than $ 10,000',
      CONTRACT_VOLUME_0: 'Volume is less than or equal to 0',
      FIXATION_PRICE_LT_100_USD: 'Fixed for less than USD 100',
      FIXATION_PRICE_LT_10000_ARS: 'Fixed for less than $ 10,000',
      FIXATION_VOLUME_LT_0T: 'Fixed volume is less than or equal to 0',
      NO: 'Without inconsistencies',
      TITLE: 'Potential inconsistencies',
      UNLOAD_APPLIED_GT_NET: 'Applied volume greater than net weight',
      YES: 'With inconsistencies'
    },
    SOURCE: {
      '1': 'API',
      '2': 'File',
      '3': 'Manual',
      '4': 'AFIP WS',
      '5': 'File'
    },
    STATUS: {
      '1': '',
      '2': 'Format error',
      '3': 'Queued',
      '4': 'Processing...',
      '5': '',
      '6': 'Retrying...',
      '7': 'Error',
      '8': 'Format validated'
    }
  },
  SIGNATURES: {
    ACTION: 'Action',
    ADD_FILES: 'Add documents to the Envelope',
    ADD_MORE_SIGNERS: 'Do you need to add signers?...',
    ADD_SIGNER: 'Add Signer',
    CREATE_ENVELOPE: 'Create Envelope',
    DEFINE_SIGNERS: 'Define signers',
    DEFINE_SIGNERS_CONFIRM: 'Once the Envelope Signers have been defined, you won\'t be able to modify them again.',
    DEFINE_SIGNERS_HELP: 'Select from the list of Signers of your Company who should sign this Envelope.',
    DOCUMENTS: 'Documents in the Envelope',
    EDIT_SIGNER: 'Edit Signer',
    ENVELOPE: 'Envelope #{{id}}',
    EXPIRATION_DATE: 'Expiration date',
    EXPIRES: 'Expires',
    HISTORY: 'History',
    LAST_MODIFIED: 'Last change',
    LINKED_TO: 'Linked to',
    MESSAGE: 'Message',
    MESSAGE_PLACEHOLDER: 'Write a message...',
    NOT_SIGNED: 'Not signed yet',
    NO_DATA: 'There are no Envelopes to show.',
    NO_SIGNERS: 'Your Company does not have Signers defined.',
    PARTIALLY_SIGNED: 'Partially signed',
    RECIPIENTS_ADD_MY_COMPANY: 'Add my Company',
    RECIPIENTS_COMPANIES: 'Companies signing the Envelope',
    RECIPIENTS_HELP: 'Each Company will then define who is authorized and competent to sign this Envelope.',
    REQUIRED_SIGNERS: 'Who should sign?',
    SEEN: 'Seen',
    SENDER: 'Sender',
    SIGN: 'Sign',
    SIGNED: 'Signed',
    SIGNERS: 'Signers',
    SIGNERS_DESCRIPTION: 'Manage the information of the individuals authorized to signing on behalf of the Company.',
    SIGNER_AUTHORIZATION: 'Power of the signer',
    SIGNER_AUTHORIZATION_HELP: 'Document that proves the power of signature of this person on behalf of the Company. This document will be available for validation by the counterparts.',
    SIGNER_AUTHORIZATION_VIEW: 'View Authorization',
    SIGNS_PENDING: '{{quantity}} signatures pending.',
    SUBJECT: 'Subject',
    SUMMARIZE: 'Showing <span class="quantity">{{quantity}}</span> Envelope(s) of <span class="quantity">{{total}}</span>',
    TIMESTAMP: 'Hour',
    USER: 'User',
    RELATED: {
      PLACEHOLDER: 'Create an envelope with related documentation to sign.',
      TITLE: 'Related envelopes'
    },
    STATUS: {
      CANCELED: 'Cencelled',
      COMPLETED: 'Completed',
      DEFINE_SIGNERS: 'Your Company did not define Signers',
      NOT_SENT: 'Not sent',
      PENDING: 'Pending signatures',
      SENT: 'This Envelope has already been sent. The Signers have been notified to their emails.',
      WAITING_SIGNERS: 'Waiting for Signers to be defined'
    }
  },
  ATTACHMENTS: {
    ERROR: 'This upload failed.',
    HEADER: 'Attachments',
    PLACEHOLDER: 'Drag and drop files here to attach them.',
    TITLE: 'Delete file',
    MODAL: {
      TITLE: 'Are you sure you want to delete this file?'
    },
    PRIVATE: {
      TITLE: 'Who should be able to see these files?',
      PUBLIC: 'Visible to all',
      PUBLIC_HELP: '<h5>Visible to all</h5>Any User with access to this content will be able to access the file.',
      PRIVATE: 'Only my Company',
      PRIVATE_HELP: '<h5>Only my Company</h5>The file will only be available to <b>Users of your Company</b>.'
    }
  },
  CONTRACTS: {
    APPLICATIONS: 'Applications',
    BUSINESS_DAYS: 'In <b>{{days}}</b> business day(s)',
    CALENDAR_DAYS: 'In <b>{{days}}</b> calendar day(s)',
    COMPANY_NOT_FOUND: '<b>Error!</b> Your Company must intervene in the Trade to be generated.',
    CONFIRM_PARTICIPANTS: 'Confirm the participants.',
    FIXATION_DEADLINE: 'Deadline:',
    FIXATIONS_NUMBER: 'in {{quantity}} Fixation(s)',
    FIXATIONS: 'Fixations',
    FIXED: 'Fixed',
    GENERATE_TRADE: 'Generate a related Trade',
    INFORMANT: 'Informant',
    INVOICING: 'Invoicing',
    NO_DATA: 'No Contracts are registered.',
    NO_FIXATION_DATA: 'No Fixations recorded',
    NOT_AVAILABLE: 'The Contract does not exist or you do not have permission to view it.',
    PARTIAL_PAYMENT: 'Partial payment',
    PREPARING: 'Preparing Contract...',
    SLATE_PRICE_HELP: 'Only if it differs from the fixation price.',
    SLATE_PRICE: 'Slate price',
    SLATE: 'Slate',
    SUMMARIZE: 'Showing <span class="quantity">{{quantity}}</span> Contract(s)',
    TOLERANCE: 'Tolerance',
    WEIGHTED_AVERAGE: 'Weighted average',
    DIFF: {
      'delivery.date_from': 'Period (From)',
      'delivery.date_to': 'Period (To)',
      'price.type': 'Price type',
      'price.unit': 'Currency',
      'price.value': 'Price',
      'quantity.type': 'Volume type',
      'quantity.unit': 'Volume unit',
      'quantity.value': 'Volume',
      NO: 'Without differences',
      TITLE: 'Contract/Trade differences:',
      YES: 'With differences'
    },
    IMPORT: {
      BUTTON: 'Import contracts',
      DESCRIPTION: 'Upload your contracts through an Excel file <code>(.xls, .xlsx)</code>'
    }
  },
  TRACKER: {
    BATCH_ID: 'Batch ID',
    EVENT: 'Event',
    GALLERY: 'Media gallery',
    INFORMATION: 'Information',
    RELATED_EVENTS: 'Related events',
    EVENTS: {
      APPLICATION: 'Agrochemical application',
      HARVEST: 'Harvest',
      IRRIGATION: 'Irrigation',
      LOAD: 'Load',
      QUALITY_CONTROL: 'Quality control',
      SOWING: 'Sowing',
      STORAGE: 'Storage',
      SUPPLIES_SALE: 'Supplies sale',
      TRADE: 'Trade operation',
      TRANSPORTATION: 'Transportation',
      UNLOAD: 'Unload',
      WEED_CONTROL: 'Weed control'
    }
  },
  FUTURES: {
    ADD: 'Add operation',
    CONDITION: 'Condition',
    EXCHANGE_RATE: 'Exchange rate',
    EXCHANGE_RATE_HELP: 'Exchange rate (Banco Nación, seller) of the previous day to report operations (<a href="http://biblioteca.afip.gob.ar/dcp/REAG01003421_2012_12_20" target="_blank">RG 3421</a>).',
    TITLE: 'Futures and Options',
    NO_DATA: 'No operations registered.',
    NO_DELIVERY: 'No delivery',
    OPERATION_DATE: 'Operation date',
    OPERATOR: 'Operator',
    POSITION: 'Position',
    POSITION_PLACEHOLDER: 'mm/yyyy',
    SUMMARIZE: 'Showing <span class="quantity">{{quantity}}</span> operation(s)',
    VOLUME: 'Volume',
    TYPES: {
      1: 'Future',
      2: 'Option'
    }
  },
  LINKER: {
    CONTRACTS: 'Contract(s):',
    DESCRIPTION: 'This tool allows you to link <b>Contracts</b> with their respective <b>Trade</b> and vice versa.',
    DESCRIPTION_NEGOTIATION: 'This tool allows you to link <b>Trades</b> with each other.',
    DESCRIPTION_CONTRACT: 'This tool allows you to link <b>Contracts</b> with each other.',
    DESCRIPTION_BATCH: 'This tool allows you to link <b>Slots</b> to a <b>Trade</b> and vice versa.',
    DESCRIPTION_BATCH_CONTRACT: 'This tool allows you to link <b>Slots</b> to a <b>Contract</b>',
    LINK: 'Link Contract and Trade',
    LINK_NEGOTIATION: 'Link Trades',
    LINK_CONTRACTS: 'Link Contracts',
    LINK_BATCH: 'Link Slots to the Trade',
    LINK_BATCH_CONTRACT: 'Link to a Contract',
    LINK_BATCH_BUTTON: 'Link to a Trade',
    LINK_BATCH_BUTTON_CONTRACT: 'Link to a Contract',
    NO_CONTRACTS: 'No <b>Contracts</b> found to link.',
    NO_TRADES: 'No <b>Trades</b> found to link.',
    SELECT_CONTRACT: 'Select the <b>Contract</b> from the list that corresponds to this <b>Trade</b>:',
    SELECT_MIRROR_CONTRACT: 'Select the mirror <b>Contract</b> from the list that corresponds to this <b>Contract</b>:',
    SELECT_TRADE: 'Select the <b>Trade</b> from the list that corresponds to this <b>Contract</b>:',
    SELECT_TRADE_NEGOTIATION: 'Select the <b>Trade</b> from the list you want to link:',
    SELECT_TRADE_BATCH: 'Select the <b>Trade</b> from the list that corresponds to these <b>Slots</b>.<br><small>These are Trades related to <b>{{counterpart}}</b>.</small>',
    SELECTED_CONTRACT: 'Selected Contract:',
    SELECTED_TRADE: 'Selected Trade:',
    TITLE: 'Link wizard',
    TRADES: 'Trade(s):',
    UNLINK: 'Unlink',
    UNLINK_TITLE: 'Are you sure you want to unlink them?',
    UNLINK_BODY: 'Trade: <samp>#{{negotiation}}</samp> and Contract: <samp>{{contract}}</samp> will be unlinked. You can re-link them again later.',
    UNLINK_BODY_BATCH: 'The Trade: <samp>#{{negotiation}}</samp> will be unlinked from these Slots. You can re-link them again later.',
    UNLINK_BODY_BATCH_CONTRACT: 'The Contract: <samp>#{{contract}}</samp> will be unlinked from these Slots. You can re-link them again later.',
    YES_UNLINK: 'Yes, unlink'
  },
  FINTECH: {
    ADD_FUNDER: 'Add Funder',
    ADD_SIGNER: 'Add signer...',
    ADMIN: 'Administration',
    AFFIDAVIT: '<b>Important:</b> The data, information and/or documentation uploaded by the user for the purposes of this application constitutes an affidavit, where the applicant responds for the validity and accuracy of all its terms.',
    AMOUNT_PENDING: 'To be defined',
    AMOUNT_REQUESTED_ABBR: 'Requested',
    AMOUNT_REQUESTED: 'Amount requested',
    APPLICANT: 'Applicant',
    APPLICATION_APPLICANT: 'Credit application > {{applicant}}',
    APPLICATION_ID: 'Credit application #{{id}}',
    APPLICATION_PRODUCT_HELP: 'Any changes to these values will only affect the Product of this particular Application.',
    APPLICATION_PRODUCT: 'Product Conditions for this Application:',
    APPLICATION_START: 'Start Application(s)',
    APPLICATIONS: 'Applications',
    APPROVED_LOC: 'Approved line of credit',
    ASSIGNMENT: 'Assignment',
    BUYER_HELP: 'Select the Contract Buyer.',
    CALCULATED_EXCHANGE: 'Calculated exchange rate: {{rate}}',
    CALCULATION_AS: 'Calculation as of {{date}}',
    COMMITTEE: 'Committee',
    CONDITIONS: 'Conditions',
    CONTRACT: 'Forward Contract',
    CREDIT_GRANTED: 'Credit granted',
    CREDIT_LINE_MAX: 'General valuation',
    CREDIT_LINE: 'Credit line',
    CREDIT_LINES_SCHEME_EDIT: 'Edit schema...',
    CREDIT_LINES_SCHEME: 'Credit line scheme',
    CREDIT_LINES_UPLOAD: 'To define credit lines, drag an XLS with a column for {{label}} followed by another with the value of the corresponding line.',
    CREDIT_LINES: 'Credit lines',
    DATE_FROM: 'From',
    DATE_TO: 'To',
    DELIVERY_PERIOD_HELP: 'According to the forward contract presented as guarantee.',
    DOA_APPROVERS_BUTTON: 'Notify the first Approver',
    DOA_APPROVERS_HELP: 'Drag the Users to define the Approvers and the order according to Delegation of Authorization (DOA).',
    DOA_APPROVERS_NO_DATA: 'There are no Users defined in your <a href="/company/{{companyId}}/team">Company\'s team</a>.',
    DOA_APPROVERS_NO_USERS: 'Need more Users?',
    DOA_APPROVERS_PLACEHOLDER: 'Drag and drop here...',
    DOA_APPROVERS: 'Approvers',
    DOA_INFO_HELP: 'This information will only be visible to Approvers.',
    DOA_INFO: 'Additional information',
    DOA_NEXT_APPROVER: 'Next Approver',
    DOA_ORDERLY_DELEGATION: 'Orderly delegation',
    DOA_PENDING_APPROVER: 'Approver pending',
    DOA_PREVIOUS_APPROVER: 'Previous approver',
    DOA_REVIEW: 'Review',
    DOA_YOUR_VOTE: 'Your vote',
    DOCUMENTATION: 'Documentation',
    DUPLICATES: 'Ignored duplicates',
    ECHEQ: 'eCheq',
    EDIT_FUNDER: 'Edit Funder',
    ENGINE_CREDIT: 'Credit engine:',
    ENGINE_SCORING: 'Engine Scoring: <b>{{scoring}}</b>',
    ESTIMATES_HELP: 'These values are estimated. The final calculation of interest, taxes and expenses will be made based on the date on which the funds are effectively credited to cancel the credit.',
    ESTIMATES: 'Estimates',
    EXPENSES: 'Expenses',
    EXPORT_APPLICATIONS: 'Export applications',
    EXPORT_BODY: 'The export of the selected applications <b>will take a few minutes</b>. When it is ready, the platform will send you an e-mail.',
    FUNDER: 'Funder',
    FUNDERS: 'Funders',
    GRANT: 'Grant',
    GRANTING: 'Granting',
    INDICATOR: 'Indicator',
    INTEREST_RATE_DETAILS: 'Rate explanation',
    INTEREST_RATE_HELP: 'Annual Effective Rate, including nominal interest, expenses and commissions.',
    INTEREST_RATE_LABEL: 'Etiqueta de la tasa',
    INTEREST_RATE: 'Interest rate',
    INTEREST: 'Interest',
    INVALIDS: 'Invalids',
    INVOICE: 'Input/service Invoice',
    LOANS: 'Loans',
    MANAGED_BY: 'Managed by:',
    MANAGER: 'Manager',
    MANAGMENT: 'Fintech management',
    MANUAL_SCORING: 'Manual scoring',
    NEW_SCREENING: 'New Screening',
    NO_DATA: 'No applications registered.',
    NO_FUNDERS_HELP: 'Are you interested in offering financial products?...',
    NO_FUNDERS: 'Your Company has no associated Funders.',
    NOTICE_HELP: 'Only when the Contract does not include an assignment clause.',
    NOTICE_LABEL: 'Request proof of assignment notice',
    NOTICE: 'Assignment notice',
    OBSERVATIONS_HELP: 'These observations will be visible to users.',
    OBSERVATIONS_PRIVATE: 'These won\'t be visible to other companies.',
    ONLY_FUNDABLES: 'Only fundable',
    PAYMENT_FREQUENCY: 'Payment frequency',
    // PENDING_PRODUCTS: '<b>{{quantity}}</b> pending product(s)',
    PENDING_REVIEW: 'Pending review',
    POLICY_INVOICE_HELP: 'You can upload more than one invoice, <b>remember to upload all the ones</b> you are financing.',
    POLICY_INVOICE: 'Policy invoice',
    PRE_APPROVED: 'Pre-approved',
    PROCESSED_DATE: 'Processed: {{date}}',
    PROCESSED: 'Items in this batch: {{quantity}}',
    PRODUCT_CURRENCY: 'Product currency',
    PRODUCT_DETAILS: 'Product details of {{company}}',
    PRODUCT_HELP: 'You can select more than one.',
    QUALIFICATION_ID: 'Scoring #{{id}}',
    QUALIFICATION_START: 'Start Scoring',
    QUALIFICATION: 'Scoring',
    QUALIFICATIONS: 'Scoring',
    QUALIFIED: 'Qualified',
    QUALIFY: 'Qualify',
    REACTIVATE_BODY: 'Reactivating this Application restarts the process by creating a related Application that inherits all the information captured in it. Are you sure?.',
    REACTIVATE_CHILD: '<b>Revisions</b>:',
    REACTIVATE_PARENT: 'This is a <b>revison</b> of',
    REACTIVATE: 'Reactivate the application',
    RECEIPT: 'Bank transfer receipt',
    REGISTERED: 'Registered',
    REPORT_BODY: 'Generating the report for the selected Applications <b>will take a few minutes</b>. Once it is ready, the platform will send you an e-mail.',
    REPORT_FUNDERS_ONLY: 'This functionality is only available for Funders.',
    REQUESTED_BY: 'Requested by:',
    REQUESTED: 'Requested',
    RETRY_FORCED_HELP: '<p>An attempt will be made to reprocess this Scoring.</p>If a rejection reason is found, the Request will be forced into <b>manual review</b>.',
    RETRY_HELP: 'An attempt will be made to reprocess this Scoring.',
    REVIEWED_BY: 'Reviewed by:',
    SCORING_APPROVED: 'Approved scoring',
    SCORING_COMMENTS: 'Scoring comments',
    SCORING_DATA: 'Scoring data',
    SCORING_IN_PROGESS: 'In progress',
    SCORING_REJECTED: 'Rejected scoring',
    SCORING_WITHDRAWN: 'Scoring withdrawn',
    SCORING: 'Scoring',
    SCREENING_BATCHES: 'Batches',
    SCREENING_DESCRIPTION: 'Our Screening process <b>determines if a Company meets the minimum requirements</b> to be a potential candidate for financial products.',
    SCREENING_FISCAL_ID: '{{fiscal_id}} numbers',
    SCREENING_HELP: '<b>The result is instantaneous</b> and the only necessary data is the {{fiscal_id}} number. You can perform a Screening of multiple companies simultaneously.',
    SCREENING_INVALID: 'Enter at least one valid {{fiscal_id}}.',
    SCREENING_MAX: 'Maximum amount allowed per batch: {{max}}',
    SCREENING_SELECT: 'Choose a Screening batch to see the results...',
    SCREENING: 'Screening',
    SEARCH_ID: 'Search by {{id}}...',
    SIGNERS_HELP: 'Select authorized signers of <b>{{company}}</b>.',
    SUMMARIZE: 'Showing <span class="quantity">{{quantity}}</span> application(s) of <span class="quantity">{{total}}</span>',
    TAXES: 'Taxes',
    UNIQUES: 'Uniques to process',
    VALID_UNTIL: 'Valid until:',
    VALIDATE: 'Validate',
    VAT: 'V.A.T.',
    VIEW_APPLICATION: 'View application',
    WITHDRAWN_BY: 'Withdrawn by:',
    WITHDRAWN_QUALIFICATION: 'Upon withdrawal of the Qualifciation, it will no longer be visible to the Manager. It is possible to restore it to its original state later.',
    WITHDRAWN_REASON_HELP: 'The reasons will be visible to all parties involved.',
    WITHDRAWN_REASON: 'Reasons',
    ADDITIONALS: {
      HELP: 'This calculator is an orientative simulation.',
      TITLE: 'Additionals estimation'
    },
    SCORING_XLS_DATA: {
      CTA: 'Do you want to apply this data?',
      DESCRIPTION: 'We detected the following information in an attached file:',
      TITLE: 'Data found'
    },
    QUALIFICATION_STATUS: {
      0: 'Pending',
      1: 'Processing',
      2: 'Approved',
      3: 'Error',
      4: 'In review',
      5: 'Rejected',
      7: 'Retrieving sources'
    },
    STEPS: {
      '1': 'Step 1',
      '2': 'Step 2',
      '3': 'Step 3',
      '4': 'Step 4',
      '5': 'Step 5'
    },
    APPLY: {
      ADDITIONAL_ACTIVITIES_VOLUME: 'Approximate annual net income from these activities.',
      ADDITIONAL_ACTIVITIES: 'Additional activities',
      ALL_SET: 'All set! No additional information is necessary to continue.',
      APPLICANT_RECIPIENT_TOGGLE: 'Specify another Company.',
      APPLICANT_RECIPIENT: 'The recipient of the funds will be the <b>Applicant</b>.',
      AUTHORIZATION_REQUIRED: '<b>Identity validation</b> of the Applicant or Legal Representative is required.',
      CIEC_REQUIRED: 'To access credit reports, it is necessary to have a <b>valid CIEC key</b> of the Applicant.',
      CROPS_HELP: 'Enter the information of your production plan for the current Campaign.',
      CROPS: 'Crops',
      FILTERS: 'Filters:',
      FISCAL_ID_HELP: '{{fiscal_id}} of the Applicant (subject of credit to qualify).',
      NEW: 'Apply for a loan',
      NO_PRODUCTS: 'Unfortunately, we do not have financial products available for this selection at this time.',
      PREVIOUS_CROPS_LOADED: 'A Crops plan from <b class="text-uppercase">{{date}}</b> was preloaded, you can review and edit it.',
      PREVIOUS_CROPS_OPTION: '<span class="material-symbols-rounded">agriculture</span>&nbsp;&nbsp;<span class="text-uppercase">{{date}}</span> <span class="text-muted">({{fields}} fields)</span>',
      PREVIOUS_CROPS: 'Previous Crops plans were found',
      REQUEST_AUTHORIZATION: 'Request validation',
      REQUEST_CIEC_HELP: 'The CIEC (confidential electronic identification) key must be provided by the <b>Applicant</b> or <b>Representative</b> of the Company.',
      REQUEST_CIEC_PROVIDER: 'Authorization and consent provided by:',
      REQUEST_CIEC: 'Request CIEC key',
      SUPPLIER_HELP_TOGGLE: 'Click here if the recipient of the funds will be the Applicant himself.',
      SUPPLIER_HELP: 'Select the inputs/service supplier Company that will receive the funds.',
      TITLE: 'Credit application'
    },
    FIELDS_TABLE: {
      ADD_CROP: 'Add crop',
      ADD: 'Add field...',
      CROP: 'Crop',
      FIELDS: 'Fields',
      FILE_FISCALID_NOT_FOUND: 'The Applicant\'s <b>CUIT</b> was not found in the uploaded document.',
      FILE_HELP: 'To add fields, <b>drag and drop</b> some of the supported file formats here.',
      FILE_WARNING_BUTTON: 'Validate location and characteristics of each one',
      FILE_WARNING: 'The following data was identified in the supplied file.',
      HECTARES: 'Hectares',
      IMPORT_LINK: 'Import from an external source',
      IMPORT_PRODUCTION_PLAN: 'Import Production Plan',
      IMPORT_SOURCES: 'Available sources',
      IMPORT: 'Import',
      LATITUDE: 'Lat.',
      LONGITUDE: 'Long.',
      MANUAL_HELP: '<b>Click on the map</b> to add a field.',
      MAP_HELP: 'You can drag the marker to adjust the coordinates of the position.',
      season: 'Season',
      technology: 'Technology',
      TABS: {
        DIFF_HELP: 'Differences between what was declared and what was obtained from the file:',
        DIFF: 'Diff',
        SOURCE_HELP: 'Obtained from the provided file:',
        SOURCE: 'Source',
        SUBMITTED_HELP: 'Declared by the user:',
        SUBMITTED: 'Submitted'
      },
      TYPES: {
        LEASED: 'Leased field',
        OWNED: 'Own field'
      }
    },
    STATUS: {
      ERROR: 'Error',
      EXPIRED: 'Expired',
      GRANTED: 'Granted!',
      POTENTIAL_DEFAULT: 'Potential default',
      REJECTED: 'Rejected',
      STEP_APPROVED: '<b>Approved!</b> Step {{index}} of {{total}}.',
      STEP_CURRENT: '<b>Under review/pending.</b> Step {{index}} of {{total}}.',
      STEP_EXPIRED: 'This application has <b>expired</b>.',
      STEP_REJECTED: 'This application has been <b>rejected</b>.',
      STEP_TURN: '<i class="material-symbols-rounded blinking">warning</i> <b>You must review/complete this step.</b> Step {{index}} of {{total}}.',
      STEP_WITHDRAWN: 'This application has been <b>withdrawn</b>.',
      WITHDRAWN: 'Withdrawn',
      REPORT: {
        '1': 'Pending',
        '2': 'Processing...',
        '3': 'Done',
        '4': 'Error'
      }
    },
    CREDIT: {
      FORMULA: 'Formula',
      FUNCTION: 'Function',
      RESULT: 'Result',
      SUBSTITUTE: 'Substitute the values',
      VALUE: 'Value',
      VARIABLE: 'Variable'
    }
  },
  DASHBOARDS: {
    DELIVERED: 'Delivered',
    DELIVERED_WITHOUT_INVOICED: 'Delivered without invoiced',
    EXPIRED_VOLUME: 'Overdue deliveries',
    IN_CONTRACTS: 'in {{quantity}} Contracts.',
    PENDING: 'Pending',
    PENDING_TO_EXPIRE: 'Expiring soon',
    TO_BE_FIXED: 'To be fixed',
    VOLUME_TBF: 'Volume to be fixed',
    VOLUME_TO_EXPIRE: 'Deliveries to expire'
  },
  LABELS: {
    COLOR: 'Color',
    CREATE: 'Create label',
    LABEL_AS: 'Label as:',
    MANAGE: 'Manage labels',
    NEW: 'New label',
    SAMPLE: 'Sample:',
    SELECTED: 'Selected elements: <span class="quantity">{{quantity}}</span>'
  },
  STATUS_LABEL: {
    CREATE: 'Create Status',
    EDIT: 'Edit Status',
    NEW: 'New Status',
    UPDATE: 'Update Status',
    WARNING: 'All the elements set as: {{status}}, will be identified as <b>No status</b>.',
    NONE: 'No status'
  },
  COMMERCIAL_ZONES: {
    CREATE: 'Create Commercial zone',
    BIND_TO: 'Link to:',
    EDIT: 'Edit Commercial zone',
    MANAGE: 'Manage Commercial zones',
    NEW: 'New Commercial zone',
    NO_UNIQUE: 'There is already a Commercial zone with that name.'
  },
  NOT_FOUND: {
    BUTTON: 'Return to main page',
    DESCRIPTION: 'If you were redirected to this page by the platform, please contact our <a href="mailto:' + EMAIL_SUPPORT + '">support team</a>.',
    SUBTITLE: 'This page does not exist',
    TITLE: 'Something went wrong... :('
  },
  PRESETS: {
    CREATE: 'Save new...',
    NEW: 'New preset',
    NO_DATA: 'No presets yet.',
    PRIVATE_HELP: 'A private preset, is <b>not visible</b> to the rest of the Company.',
    TITLE: 'Presets',
    FILTERS_LABEL: 'Filter History',
    SAVE_FILTERS: 'Save filters',
    SAVE_FILTERS_DESC: 'When you save these filter settings, the next time you enter the section, they will be applied by default.',
    FILTERS_DELETE_TOOLTIP: 'Delete configuration',
    FILTERS_DELETE_BODY: 'Are you sure you want to delete this preset filter configuration?',
    FILTERS_ACTIVE_LABEL: 'Pre-applied filters <span class="material-symbols-rounded">filter_alt</span>'
  },
  INTEGRATIONS: {
    API_DOCUMENTATION_HELP: 'To access our API documentation, contact our <a href="mailto:' + EMAIL_SUPPORT + '">support team</a>.',
    API_DOCUMENTATION: 'API documentation',
    TITLE: 'API integrations',
    MONITOR: 'API Integrations Monitor',
    WEBHOOK_MODEL: 'Webhook payload model',
    WEBHOOKS_HELP: 'Webhooks are HTTP POST calls that are made on specific events. You can specify up to 5 URLs.',
    WEBHOOKS: 'Webhooks'
  },
  VOLUME: {
    ADDITIONAL_HELP: 'Commissions, freight, etc.',
    ADDITIONAL: 'Additional',
    AMOUNT: 'Amount to settle',
    APPLY: 'Apply',
    CALCULATE: 'Calculate',
    CALCULATOR: 'Volume calculator',
    DAYS: 'Days of duration',
    GRANTING_DATE: 'Granting date',
    MULTIPLE: 'Volume (multiple of {{multiple}})',
    NEEDED_WARRANTY: 'Necessary volume + warranty',
    NEEDED: 'Necessary volume',
    PRICE: 'Reference price',
    SUBTOTAL: 'Subtotal',
    TOTAL_AMOUNT: 'Total (amount + additional)',
    VAT_INCLUDED: 'VAT included',
    WARRANTY: 'Warranty'
  },
  LIQUIDACIONES: {
    SUMMARIZE: 'Showing <span class="quantity">{{quantity}}</span> Settlement(s)',
    TITLE: 'Settlements',
    TIPO: {
      '1': 'Primary',
      '2': 'Secondary',
      '3': 'Adjusment'
    }
  },
  DOC_CUSTOM: {
    FOOTER: 'Footer',
    HEADER: 'Header',
    HELP: 'You can include a legend in the header and footer of the documents generated by the platform where your Company is involved.',
    INFO: 'Any changes will only be reflected in the documents generated from now on.',
    TITLE: 'Customize documents'
  },
  WORKFLOW: {
    GROUPS: {
      account_opening: 'Account opening',
      analysis: 'Analysis',
      application_signing: 'Application signing',
      approval: 'Approval',
      axc_contract: 'AxC Contract',
      bail_signing: 'Bail signing',
      branch: 'Branch',
      buro_externo: 'External bureau',
      buro_interno: 'Internal bureau',
      cession: 'Assignment',
      collaterals: 'Collaterals',
      committee: 'Committee',
      confirmation: 'Confirmation',
      credit_committee: 'Credit committee',
      credit_contract: 'Credit contract',
      doa: 'Review',
      documentation: 'Documentation',
      echeq: 'eCheq',
      endorsement: 'Endorsement',
      granting: 'Granting',
      invoice: 'Invoice',
      liquidation: 'Liquidation',
      portfolio: 'Customer Analysis',
      promissory_note: 'Promissory note',
      proof: 'Proof',
      signing: 'Signatures',
      site_review: 'Inspection visit',
      warranty_review: 'Warranty review'
    },
    FIELD: {
      account_opening_letter: 'Account Opening Letter',
      agriculturalForm: 'Agricultural form',
      alyc: 'ALyC',
      amount: 'Amount requested',
      approved_portfolio: 'Approved clients',
      approvers: 'Approvers',
      assembly_minute: 'Assembly minute',
      assembly_minutes: 'Last Assembly Minute',
      assessment_certificate: 'Certificated of assessment fredom',
      authorities_act: 'Authorities Appointment Act',
      axc_contract: 'AxC Contract',
      bail_signers_ids: 'DNI de los firmantes de la fianza',
      bail_signers: 'Bail signers',
      bailor_address: 'Bailor address',
      bailor: 'Bailor',
      balance_sheet_approval: 'Balance sheet approvals',
      bank_financial_debt: 'Bank and financial debt',
      bank_statements: 'Bank statements',
      broker: 'Broker',
      business_review: 'Business review',
      business_supplier_review: 'Business and supplier review',
      buyer: 'Buyer',
      buyerRFC: 'Buyer\'s RFC',
      certified_assets_manifestation: 'Certified Assets Manifestation',
      cession_notice: 'Assignment notice',
      charges_act: 'Act of distribution of charges',
      client_concept: 'Customer concept',
      collateral_amount: 'Collateral amount',
      commercial_appraisal: 'Commercial appraisal',
      commercialDebt: 'Commercial debt',
      company_acts: 'Company Acts',
      constitutive_act: 'Constitutive Act',
      contract: 'Contract',
      corporate_composition: 'Corporate composition',
      credit_amount: 'Amount credited',
      credit_analysis: 'Credit analysis',
      credit_application_form: 'Credit Application Form',
      credit_bureau_consultation: 'Credit Bureau Consultation',
      current_account_ars: 'Current Account AR$',
      current_account_usd: 'Current Account USD',
      ddjjBienes: 'Affidavit of Personal Assets',
      ddjjGanancias: 'Affidavit of Earnings',
      ddjjIVA: 'Affidavit of VAT',
      debit_note: 'Debit note',
      delivery_range: 'Delivery period',
      due_date: 'Due date',
      echeq_amount: 'eCheq amount',
      echeq: 'eCheq',
      electronic_invoices: 'Electronic invoice(s)',
      FILES: 'File(s)',
      financeableCrop: 'Financeable crop',
      financial_statements: 'Financial statements',
      financialDebt: 'Long-term financial debt',
      fiscal_proof: 'Proof of fiscal situation',
      fwd_contract: 'Forward contrat',
      granting_date: 'Granting date',
      guaranteed_amount: 'Guaranteed amount',
      has_alyc_account: 'Has an account in an ALyC',
      has_rosental_account: 'Has a Rosental account',
      id_certified_copy: 'ID Certified copy',
      IDBack: 'Back of document',
      IDFront: 'Front of document',
      iibb: 'Certificate of registration IIBB',
      insurance_policy: 'Insurance policy',
      investment_project: 'Investment project/budget',
      invoices: 'Input/service Invoice(s)',
      IOU: 'Promissory note',
      issue_date: 'Issue date',
      iva: 'Certificate of registration IVA',
      last_three_balances: 'Last three balance sheets',
      lastBalance: 'Last balance sheet',
      lastTwoBalances: 'Last two balance sheets',
      locality_and_province: 'Locality and province',
      observations: 'Observations',
      openingForm: 'Opening form',
      personalPropertyDeclaration: 'Personal property declaration',
      policy_invoices: 'Policy invoice(s)',
      promissory_note_amount: 'Promissory note amount',
      proof_of_address: 'Proof of address',
      property_lease: 'Property Title/Lease Agreement',
      real_estate_insurance: 'Real estate insurance',
      reforms_statutes: 'Reforms to statutes',
      representative_ids: 'ID of representatives and attorneys',
      representative_ine: 'Official identification (INE) of legal representatives',
      representative_powers: 'Powers of legal representatives',
      requires_cession_notice: 'Requieres proof of assignment notice',
      RPC_enrollment: 'RPC enrollment',
      sale_deed: 'Sale deed',
      sales_detail: 'Sales detail',
      settle_amount: 'Amount to settle',
      settlement_receipt: 'Settlement receipt',
      sgr: 'S.G.R.',
      shareholders_assets_declaration: 'Shareholder\'s Declaration of assets',
      shareholders_book: 'Shareholders book',
      shareholders_credit_bureau_consultation: 'Shareholder\'s Credit Bureau Consultation',
      shareholders_ine: 'Official identification (INE) of shareholders',
      shareholders_list: 'Shareholders list',
      shareholders_proof_of_address: 'Proof of address of shareholders',
      shareholding_structure: 'Shareholding structure',
      signers: 'Signers',
      sme_certificate: 'SME certificate',
      stamp_tax_exemption: 'Stamp Tax Exemption Certificate',
      statute_modifications: 'Modifications to the statute',
      statute: 'Statute of the Company',
      supplier_quote: 'Supplier quote(s)',
      tax_jurisdiction: 'Tax jurisdiction',
      term: 'Term',
      transfer_amount: 'Amount of credit granted',
      transfer_receipt: 'Bank transfer receipt',
      visit_report: 'Inspection Visit Report',
      warranty_certificate: 'Certificate of guarantee',
      warranty: 'Warranty',
      water_use_concession: 'Water use concession'
    },
    POSSIBILITY: {
      approve: 'Approve',
      confirm: 'Confirm',
      continue: 'Continue',
      error: 'Error',
      grant: 'Grant',
      reject: 'Reject',
      return: 'Back to the previous step',
      send: 'Send'
    },
    TYPE: {
      0: "WAIT",
      1: "END",
      2: "START",
      3: "TRANSITION",
      4: "FORM",
      5: "REVIEW",
      6: "CHOICE"
    }
  },
  METADATA: {
    IMPORTANT_ONLY: 'Important only',
    TITLE: 'Additional data'
  },
  DOA: {
    APPROVAL: 'Approval',
    LINKED_TO: 'Related to',
    TITLE: 'Approvals',
    WAITING: 'Waiting for decision...',
    STATUS: {
      APPROVED: 'Approved',
      DISAPPROVED: 'Disapproved',
      PENDING: 'Pending',
      RETIRED: 'Withdrawn'
    }
  },
  FORM_REUSE: {
    HELP: 'Select the fields you want to reuse:',
    REUSE: 'Reuse',
    TITLE: 'Reuse data...'
  },
  AUTHORIZATIONS: {
    ADD_MORE: 'Define new Representative...',
    CREATE: 'Identity validation',
    DUE_DATE: 'Due date',
    FISCAL_PROOF_HELP: 'Upload the file issued by SAT. Supported format: PDF.',
    FISCAL_PROOF: 'Proof of fiscal situation',
    MESSAGE_PLACEHOLDER: 'I authorize/accept the following conditions...',
    MESSAGE: 'Message',
    PROVIDER: 'Identity validation performed on:',
    REPRESENTATIVES: 'Representatives',
    REQUESTED: 'Requested',
    ENTITY_HELP: {
      DEFAULT: 'To request authorization, it is necessary to upload the Company\'s <b>PROOF OF FISCAL SITUATION</b>.',
      FISCAL_ID: 'To request authorization for the CURP: <samp>{{reference}}</samp>, it is necessary to upload the Company\'s <b>PROOF OF FISCAL SITUATION</b>.',
      FORM: 'To request authorization for the {{label}}: <samp>{{reference}}</samp>, it is necessary to complete the following form.'
    },
    STATUS: {
      AUTHORIZED: 'Authorized',
      CANCELED: 'Canceled',
      PENDING: 'Pending'
    }
  },
  NOTES: {
    ADD_COLUMN: 'Add column',
    ADD_COMPANY_HELP: 'Enter all the <b>{{fiscal_id}} numbers</b> you want to add.',
    FOR: 'Info for {{ company }}',
    TYPE: 'Type'
  },
  PORTFOLIO: {
    ACCUMULATED_LINE: 'Accumulated line',
    ADD_HELP: 'Enter the number of {{label}} of all customers to analyze. Once the analysis is finished, you will have the possibility to include more.',
    ADD: 'Add customers',
    FINISH: 'End analysis',
    NO_DATA: 'No customers of <b>{{company}}</b> have yet been reported for analysis.',
    PROCESSED: 'Already processed'
  },
  ACCOUNT: {
    ROLES: {
      admin: 'Administrator',
      commercial_with_users: 'Commercial (with users)',
      commercial: "Commercial",
      counterpart_admin: 'Counterpart Admin',
      operations: 'Operations',
      logistics: 'Logistics',
      business_officer: 'Business Officer',
      superadmin: 'Super Admin',
      superadmin_readonly: 'Super Admin (read only)',
      superadmin_writeonly: 'Super Admin (write only)'
    }
  },
  API_KEY: {
    DESCRIPTION: 'To make use of our services outside the platform, an API key is required.',
    GENERATE: 'Generate API key',
    NO_KEYS: 'There are no API keys available for your company.',
    SAMPLE: 'Authentication using API key',
    TITLE: 'API keys',
    STATUS: {
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
      DELETED: 'Deleted'
    }
  },
  CLIENT_CREDENTIALS: {
    TITLE: 'Customer credentials',
    GENERATE: 'Generate credentials',
    NO_CREDENTIALS: 'No credentials are available for your company',
    CREATED: 'Customer credentials created',
    OAUTH: {
      TITLE: 'Authorize the connection between <b>{{company}}</b> and your <b>Agree</b> account.',
      LABEL: 'Do you agree to give him/her access to your account?',
      DESCRIPTION: 'Do you agree to give them access to your account? Allow <b>{{company}}</b> to access your Agree account and enjoy an integrated and enhanced experience. Your information will be secure and we will use only the necessary data.',
      REDIRECT_MESSAGE: 'Authorize will redirect you to {{url}}',
      AUTHORIZE: 'Authorize',
      REDIRECT_TO: 'If you authorize you will be redirected to <u>{{url}}</u>',
      REDIRECTING: 'Redirecting...'
    }
  },
  ADMIN_CIEC_REQUESTS: {
    HEADER_LINK: 'CIEC Request Manager',
    TABLE_TITLE: 'CIEC Request Manager',
    TABLE_DESCRIPTION: 'View the status of applications',
    VALIDITY_COLUMN: 'Status of the CIEC',
    RESEND_REQUEST: 'Resend request',
    STATUS_COLUMN: 'Status of the request',
    MODAL: {
      BODY: 'If the request has been pending for an extended period of time, it is recommended to resend the e-mail.'
    },
    STATUS: {
      UNKNOWN: 'Unknown',
      PENDING: 'Pending',
      VALID: 'Valid',
      INVALID: 'Invalid',
      ERROR: 'Error',
      DEACTIVATED: 'Deactivated',
      COMPLETED: 'Completed'
    }
  },
  NIP: {
    ADMIN_TITLE: 'PIN Request Manager',
    PROVIDER: 'Authorization and consent provided with PIN signature by:',
    REQUEST: 'Request PIN code',
    REQUEST_HELP: 'The <b>Applicant must provide authorization to consult the credit report</b> through his or her PIN signature. This code will be sent to you by e-mail.',
    REQUIRED: 'The <b>authorization through PIN</b> of the Applicant or Legal Representative is necessary.'
  },
  ADMIN_TENANTS: {
    HEADER_LINK: 'Administrador de Tenants',
    TABLE_DESCRIPTION: 'Administra los multiples tenants de la plataforma',
  },
  EPYME: {
    STATUS: {
      canceled: 'Canceled',
      completed: 'Completed',
      expired: 'Expired',
      failed: 'Failed',
      issued: 'Issued',
      pending: 'Pending',
      rejected: 'Rejected'
    }
  }
};
