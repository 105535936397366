import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { LoginService } from '../../auth/services/login.service';
import { DataDogLoggerService } from '../../services/data-dog-logger.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit, OnDestroy {

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean = true;
  public message: string;
  public error: boolean;

  private subscriptions: Subscription[] = [];

  constructor(
    // private router: Router,
    private loginService: LoginService,
    // private flashService: FlashService,
    private activatedRoute: ActivatedRoute,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  /**
   * Initializes the component by verifying the user's email.
   * Retrieves userId and token from the route parameters.
   * Subscribes to the email verification service and handles success and error responses.
   */
  ngOnInit(): void {
    const userId = this.activatedRoute.snapshot.params['userId'];
    const token = this.activatedRoute.snapshot.params['token'];

    this.subscriptions.push(this.loginService.verify(userId, token).subscribe({
      next: response => {
        // this.flashService.report('verification_completed');
        // this.flashService.dismiss('verification_pending');
        this.message = 'HEADER.VERIFICATION_SUCCESSFUL';
        this.error = false;
        this.processing = false;
        // this.router.navigate(['/companies']);
      },
      error: error => {
        // this.flashService.report('verification_failed');
        // this.flashService.dismiss('verification_pending');
        this.message = 'HEADER.VERIFICATION_FAILED';
        this.error = true;
        this.processing = false;
        // this.router.navigate(['/companies']);
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
